import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import FileUploaderB from "../../components/FileUploader_B";


// React-router
import { useNavigate } from "react-router-dom";


// Enpoints
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";
import CustomLoader from "../../components/CustomLoader";
import { idExtractor } from "../../helpers/idExtractor";

const CONSTANT = {
  redirectUrl: '/generales/comunas',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear comuna',
  title: 'Comuna',
  queryUrl: create.comunas,
  fetchRegiones: getAll.regiones + '/buscar',
  fetchCiudades: getAll.ciudades + '/buscar',
  fetchDistritos: getAll.distritos + '/buscar',
  fetchComunasErp: getAll.erp_comunas,

  initialFormState: {
    region_id: '',
    ciudad_id: '',
    distrito_id: '',
    nombre: undefined,
    cod_maxi: '',
    estatus: 1,
    costo_despacho_b2b: ''
  },
  selectFilterInitialState: {
    regiones: [],
    ciudades: [],
    distritos: [],
    erpComunas: []
  }
}


const handleSelectFiltersData = async () => {
  const regiones = await axios(CONSTANT.fetchRegiones,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.regiones] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const ciudades = await axios(CONSTANT.fetchCiudades,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.ciudades] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const distritos = await axios(CONSTANT.fetchDistritos,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.distritos] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const erpComunas = await axios(CONSTANT.fetchComunasErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.comunas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { regiones, ciudades, distritos, erpComunas }
}


const Create = () => {
  const [imagen_bandera, setImagen_bandera] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let requestData = new FormData()
    requestData.append('nombre', form?.nombre)
    requestData.append('imagen_bandera', imagen_bandera[0]?.file)
    requestData.append('cod_maxi', form?.cod_maxi?.split('-')[0])
    requestData.append('ciudad_id', idExtractor(form?.ciudad_id))
    requestData.append('region_id', idExtractor(form?.region_id))
    requestData.append('distrito_id', idExtractor(form?.distrito_id))
    requestData.append('estatus', Number(form?.estatus))

    form?.costo_despacho_b2b?.length && requestData.append('costo_despacho_b2b', idExtractor(form?.costo_despacho_b2b))

    setIsConsulting(true)

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data: requestData
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.regiones.length && selectFilter.distritos.length && selectFilter.ciudades.length && selectFilter.erpComunas.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={form?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-8'
              />

              <FormInput
                labelText='Costo despacho (B2B)'
                name='costo_despacho_b2b'
                value={form.costo_despacho_b2b}
                handleValue={handleInputChange}
                size='col-12'
                type='number'
                sizeDesk='col-md-4'
              />

              <SelectFilter
                labelText="* Ciudad"
                name="ciudad_id"
                value={form?.ciudad_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.ciudades?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="* Región"
                name="region_id"
                value={form?.region_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.regiones?.map(({ id, nombre }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="* Distrito"
                name="distrito_id"
                value={form?.distrito_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.distritos?.map(({ id, nombre }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="* Cod. Maxi"
                name="cod_maxi"
                value={form?.cod_maxi?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.erpComunas?.map(({ cod_maxi, nombre }) => ({ name: `${nombre ?? 'Error'} | ${cod_maxi ?? ''}`, id: cod_maxi ?? '*NULL*' }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FileUploaderB labelText='* Bandera' handleValue={setImagen_bandera} value={imagen_bandera} />
            </div>

            <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Table" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;
