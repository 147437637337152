import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Enpoints
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";
import CustomLoader from "../../components/CustomLoader";
import { idExtractor } from "../../helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/proveedores/productos-propios-proveedor',
  nullObject: { id: '*NULL*', nombre: 'Error' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitleCreate: 'Crear producto propio',
  componentTitleUpdate: 'Editar producto propio',
  title: 'Producto propio en proveedor',

  queryUrl: create.productos_propios_proveedor,
  fetchProductosProveedores: getAll.productos_proveedores + `/listar`,
  fetchProductos: getAll.productos + `/listar?limit=99999&asignacion_masiva=true&bodegas_con_saldo=0&stock_erp=0`,

  initialFormState: {
    producto_id: '',
    producto_proveedor_id: '',
    estatus: true
  },

  selectFilterInitialState: {
    productosProveedores: [],
    productos: []
  }
}


const handleSelectFiltersData = async () => {
  const productosProveedores = await axios(CONSTANT.fetchProductosProveedores, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    method: 'POST'
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.errorObject]
    })

  const productos = await axios(CONSTANT.fetchProductos, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    method: 'POST'
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.productos?.data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.errorObject]
    })

  return { productosProveedores, productos }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const { id } = useParams()


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }
  console.log(selectFilter)

  // EFFECTO QUE SETEA form
  useEffect(() => {
    if (!id || !selectFilter.proveedorCategoria.length) return

    axios(CONSTANT.queryUrl + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => {
        let res = data
        const { id: provId, categoria: provCat, proveedor: prov } = selectFilter.proveedorCategoria.find(({ id }) => id === res.proveedor_categoria_id)
        setForm({
          ...data,
          proveedor_categoria_id: `${provId}-${prov?.nombre ?? 'N/A'} | ${provCat?.nombre ?? 'Sin selección'}`
        })

      })
      .catch(err => console.error(err))

    return () => {
      setForm(null)
    }
  }, [id, selectFilter.proveedorCategoria])


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!form.producto_proveedor_id || form.producto_proveedor_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'El producto-proveedor es obligatorio', icon: 'warning' }))
    if (!form.producto_id || form.producto_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'El producto es obligatorio', icon: 'warning' }))

    let url = id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: id ? 'PUT' : 'POST',
      data: {
        producto_id: idExtractor(form.producto_id),
        producto_proveedor_id: idExtractor(form.producto_proveedor_id),
        estatus: form.estatus
      }
    }

    axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro ${id ? 'actualizado' : 'creado'} con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);

        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {selectFilter.productos.length && selectFilter.productosProveedores.length
          ? <CustomForm size="" title={id ? CONSTANT.componentTitleUpdate : CONSTANT.componentTitleCreate}>
            <div className="row">
              <Switch
                name="estatus"
                value={form?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <SelectFilter
                labelText="Producto-Proveedor"
                name="producto_proveedor_id"
                handleValue={handleInputChange}
                optionObj={selectFilter?.productosProveedores?.map(({ descripcion, nombre, sku_proveedor, id }) => ({ name: `${descripcion ?? nombre ?? 'N/A'} | ${sku_proveedor ?? 'N/A'}`, id }))}
                size="col-12 col-md-12"
                value={form?.producto_proveedor_id.split('-')[1] ?? 'Seleccione'}
              />

              <SelectFilter
                labelText="Productos"
                name="producto_id"
                handleValue={handleInputChange}
                optionObj={selectFilter?.productos?.map(({ nombre, sku, id }) => ({ name: `${nombre ?? 'N/A'} | ${sku ?? 'N/A'}`, id }))}
                size="col-12 col-md-12"
                value={form?.producto_id.split('-')[1] ?? 'Seleccione'}
              />
            </div>

            <Button type="submit" text={id ? 'Editar' : 'Crear'} color="primary" icon="Check" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Form;
