import {
   FILTER_CLEAR,
   FILTER_PAGOS_PEDIDOS_INPUT,
   RESULTADOS_PAGOS_PEDIDOS,
} from "../redux-types";

export const pagosPedidoFilter = ({ key, value }) => ({ type: FILTER_PAGOS_PEDIDOS_INPUT, payload: { key, value } })

export const resultadosPagosPedidos = (data) => ({ type: RESULTADOS_PAGOS_PEDIDOS, payload: data })

// export const filtrosPersistentesPagosPedidos = (data) => ({ type: FILTROS_PERSISTENTES_GARANTIAS, payload: data })

export const clearFilterPagosPedidos = () => ({ type: FILTER_CLEAR });
