import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_PROVEEDORES_TRANSPORTES,
} from "../redux-types";

const initialState = {
   filtros: {
   },
   resultados: null
}


export const proveedoresTransportesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_PROVEEDORES_TRANSPORTES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state, filtros: { ...initialState.filtros }
         };

      default:
         return state;
   }
};