import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import Button from "components/Button";
import CustomForm from "components/CustomForm";
import FormInput from "components/FormInput";
import FileUploaderB from "components/FileUploader_B";
import TextareaInput from "components/TextareaInput";
import CustomLoader from "components/CustomLoader";
import SelectFilter from "components/SelectFilter";


// React-router
import { useNavigate, useParams, useSearchParams } from "react-router-dom";


// Endpoints
import { create } from "endpoints/create";
import { getOne } from "endpoints/getOne";


// Helpers
import { idExtractor } from "helpers/idExtractor";


// Services
import { getDetailedGarantiaPedido } from "services/garantias/tienda/getPedidoDatos.service";
import { searchRegiones } from "services/regiones/search.service";
import { getAllPosiciones } from "services/garantias/posiciones/getAll.service";
import { getAllGarantiasLaterales } from "services/garantias/laterales/getAll.service";
import { getAllComunas } from "services/comunas/getAllComunas.service";
import { responseToasterContent } from "helpers/toasterContent";
import CheckBoxInput from "components/CheckboxInput";
import { getAllGarantiasDanos } from "services/garantias/danos/getAll.service";
import { getAllGarantiasEstados } from "services/garantias/estados/getAll.service";
import { getAllGarantiasRespuestas } from "services/garantias/repuestas/getAll.service";
import { getAllGarantiasResultados } from "services/garantias/resultados/getAll.service";
import { getAllGarantiasCausas } from "services/garantias/causas/getAll.service";
import { searchUsuarios } from "services/usuarios/getAll.service";
import { getAllGarantiasRecomendaciones } from "services/garantias/recomendaciones/getAll.service";
import { getAllGarantiasConclusiones } from "services/garantias/concluciones/getAll.service";
import { getDetailedPedido } from "services/pedidos/getDetail.service";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/garantias',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} garantía`,
  title: 'Garantías',

  // NOTA: endpoint no habilitado porque no existe la creacion desde el panel
  queryUrl: create.garantia,

  fecthData: getOne.garantias,

  form: {
    producto: '', //obj - helper
    region: '', //obj - helper

    pedido_id: '', //int
    posicion_id: '', //int
    lateral_id: '', //int 
    producto_id: '', //int

    producto_sku: '', //str
    producto_nombre: '', //str
    producto_articulo: '', //str
    producto_tipo: '', //str
    producto_dot: '', //str
    producto_origen: '', //str

    cliente_nombre: '',
    cliente_email: '',
    cliente_telefono: '',
    cliente_rut: '',
    cliente_domicilio: '', //opt
    cliente_codigo_postal: '', //opt
    cliente_comuna_id: '', //opt

    vehiculo_marca: '',
    vehiculo_modelo: '',
    vehiculo_año: '',
    numero_chasis: '',
    patente: '',

    numero_factura: '',
    evidencias: [], //arr
    comentario: '', //opt
  },

  updateForm: {
    producto: '', //obj - helper
    region: '', //obj - helper

    pedido_id: '', //?int
    usuario_id: '', //?int

    estado_id: '', //?int 
    posicion_id: '', //?int
    lateral_id: '', //?int 
    daño_id: '', //?int 
    daño_dos_id: '', //?int 
    daño_tres_id: '', //?int 
    causa_id: '', //int 
    evaluador_id: '', //int 
    resultado_id: '', //?int 
    respuesta_id: '', //?int 

    patente: '', //?str
    vehiculo_marca: '', //?str
    vehiculo_modelo: '', //?str
    vehiculo_año: '', //?int
    numero_chasis: '', //?str

    producto_sku: '', //?str
    producto_nombre: '', //?str
    producto_articulo: '', //?str
    producto_tipo: '', //?str
    producto_dot: '', //?str
    producto_origen: '', //?str
    producto_id: '', //?int

    cliente_nombre: '', //?str
    cliente_rut: '', //?str
    cliente_email: '', //?str
    cliente_telefono: '', //?str
    cliente_domicilio: '', //?str
    cliente_codigo_postal: '', //?str
    cliente_comuna_id: '', //?str

    numero_factura: '', //?int
    comentario: '', //opt

    conclusion_id: '', //?int 
    recomendacion_id: '', //?int
    banda_residual: '', //?str 
    porcentaje_bonificacion: '', //?str 
    envio_fabrica: '', //?bool 
    codigo_evaluador: '', //?str 
    // observacion: '', //?str 

    fecha_creacion: '', //?str 
    fecha_recepcion: '', //?str 
    fecha_envio: '', //?str 
  },

  selectFilter: {
    comunas: [],
    daños: [],
    causas: [],
    estados: [],
    regiones: [],
    laterales: [],
    posiciones: [],
    respuestas: [],
    resultados: [],
    evaluadores: [],
    lineasProductos: [],
    productoArticulos: [
      { id: 'CUBIERTA', nombre: 'CUBIERTA' },
      { id: 'CAMARA', nombre: 'CAMARA' },
      { id: 'PROTECTOR', nombre: 'PROTECTOR' },
    ],
    productoTipo: [
      { id: 'SIN CAMARA', nombre: 'SIN CAMARA' },
      { id: 'CON CAMARA', nombre: 'CON CAMARA' },
    ],
  }
}


const fetchSelectFilterData = async () => {
  return await Promise.all([
    searchRegiones()
      .then(({ data }) => [CONSTANT.unselectedObject, ...data.regiones.data])
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllPosiciones()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasLaterales()
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasDanos()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasEstados()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasRespuestas()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasResultados()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasCausas()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err);
        return []
      }),

    searchUsuarios({ ejecutivo: 1 })
      .then(({ data }) => {
        return data.data
      })
      .catch(err => {
        console.error(err);
        return []
      }),

    getAllGarantiasRecomendaciones()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllGarantiasConclusiones()
      .then(({ data }) => data.data)
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      })
  ])
    .then(([regiones, posiciones, laterales, daños, estados, respuestas, resultados, causas, evaluadores, recomendaciones, conclusiones]) => ({ regiones, posiciones, laterales, daños, estados, respuestas, resultados, causas, evaluadores, recomendaciones, conclusiones }))
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)
  const [queryParams, setQueryParams] = useState(null)
  const [evidencias, setEvidencias] = useState([])


  // EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fetchSelectFilterData()
      .then(res => {
        setSelectFilter(prev => ({ ...prev, ...res }))
      })
  }, [])


  // EFECTO QUE SETEA LA DATA DE LAS COMUNAS
  useEffect(() => {
    if (!form.region) return

    getAllComunas(undefined, undefined, undefined, {
      filtros: {
        region_id: [idExtractor(form?.region)]
      }
    })
      .then(({ comunas: res }) => {
        const comunas = res?.data.map(({ id, nombre }) => ({ id, nombre }))

        setSelectFilter(prev => ({ ...prev, comunas }))
      })
  }, [form?.region])


  useEffect(() => {
    if (!params) return
    let obj = {}
    for (const [k, v] of params) { obj = { ...obj, [k]: v } }

    setQueryParams(obj)
  }, [params])


  useEffect(() => {
    if (id || !queryParams) return

    getDetailedPedido(queryParams?.pedido_id)
      .then(({ data }) => {
        setForm(prev => ({
          ...prev,
          numero_seguimiento: data?.numero_seguimiento,
          cliente_nombre: data?.usuario?.nombre,
          cliente_email: data?.usuario?.email,
          cliente_telefono: data?.usuario?.fono,
          cliente_rut: data?.usuario?.rut,
        }))

        setSelectFilter(prev => ({
          ...prev,
          lineasProductos: data?.lineas_pedido.map(({ producto, producto_id }) => ({
            id: producto_id,
            nombre: producto?.nombre,
            sku: producto?.sku
          }))
        }))
      })
      .catch(err => console.error(err))
  }, [id, queryParams])


  // EFECTO QUE SETEA LA DATA DEL FORMULARIO DE EDICION
  useEffect(() => {
    // return
    if (
      !id
      // || !selectFilter?.lineasProductos?.length
      // || !selectFilter?.daños?.length
      // || !selectFilter?.estados.length
      || !selectFilter?.posiciones.length
      // || !selectFilter?.respuestas.length
      // || !selectFilter?.resultados.length
      || !selectFilter?.laterales.length
      || !selectFilter?.regiones.length
      // || !selectFilter?.comunas.length
      || !selectFilter?.productoTipo.length
      || !selectFilter?.productoArticulos.length
    ) return

    axios(CONSTANT.fecthData(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const selectedDaño = selectFilter?.daños.find(({ id }) => id === data.daño_id)
        const selectedEstado = selectFilter?.estados.find(({ id }) => id === data.estado_id)
        const selectedPosicion = selectFilter?.posiciones.find(({ id }) => id === data.posicion_id)
        const selectedRespuesta = selectFilter?.respuestas.find(({ id }) => id === data.respuesta_id)
        const selectedResultado = selectFilter?.resultados.find(({ id }) => id === data.resultado_id)
        const selectedLateral = selectFilter?.laterales.find(({ id }) => id === data.lateral_id)
        const selectedRegion = selectFilter?.regiones.find(({ id }) => id === data.comuna?.region_id)
        const selectedComuna = selectFilter?.comunas.find(({ id }) => id === data.comuna?.id)
        const selectedProductoTipo = selectFilter?.productoTipo.find(({ id }) => id === data?.producto_tipo)
        const selectedProductoArticulo = selectFilter?.productoArticulos.find(({ id }) => id === data?.producto_articulo)
        const selectedEvaluadores = selectFilter?.evaluadores.find(({ id }) => id === data?.evaluador?.id)
        const selectedCausa = selectFilter?.causas.find(({ id }) => id === data?.causa?.id)
        // const selectedProducto = selectFilter?.lineasProductos.find(({ sku }) => sku === data?.producto_sku)

        setForm({
          ...data,
          daño_id: `${selectedDaño?.id ?? '*NULL*'}-${selectedDaño?.daño ?? 'Sin selección'}`,
          region: `${selectedRegion?.id ?? '*NULL*'}-${selectedRegion?.nombre ?? 'Sin selección'}`,
          cliente_comuna_id: `${selectedComuna?.id ?? '*NULL*'}-${selectedComuna?.nombre ?? 'Sin selección'}`,
          lateral_id: `${selectedLateral?.id ?? '*NULL*'}-${selectedLateral?.lateral ?? 'Sin selección'}`,
          estado_id: `${selectedEstado?.id ?? '*NULL*'}-${selectedEstado?.estado ?? 'Sin selección'}`,
          posicion_id: `${selectedPosicion?.id ?? '*NULL*'}-${selectedPosicion?.posicion ?? 'Sin selección'}`,
          respuesta_id: `${selectedRespuesta?.id ?? '*NULL*'}-${selectedRespuesta?.titulo ?? 'Sin selección'}`,
          resultado_id: `${selectedResultado?.id ?? '*NULL*'}-${selectedResultado?.resultado ?? 'Sin selección'}`,
          producto_tipo: `${selectedProductoTipo?.id ?? '*NULL*'}-${selectedProductoTipo?.nombre ?? 'Sin selección'}`,
          producto_articulo: `${selectedProductoArticulo?.id ?? '*NULL*'}-${selectedProductoArticulo?.nombre ?? 'Sin selección'}`,
          evaluador_id: `${selectedEvaluadores?.id ?? '*NULL*'}-${selectedEvaluadores?.nombre ?? 'Sin selección'}`,
          causa_id: `${selectedCausa?.id ?? '*NULL*'}-${selectedCausa?.causa ?? 'Sin selección'}`,
          // producto: `${selectedProducto?.id}-${selectedProducto?.sku} | ${selectedProducto?.nombre}`
        })
      })
      .catch(err => console.error(err))
  }, [id,
    selectFilter?.lineasProductos,
    selectFilter?.daños,
    selectFilter?.estados,
    selectFilter?.posiciones,
    selectFilter?.respuestas,
    selectFilter?.resultados,
    selectFilter?.laterales,
    selectFilter?.regiones,
    selectFilter?.comunas,
    selectFilter?.productoTipo,
    selectFilter?.productoArticulos,
    selectFilter?.causas,
    selectFilter?.evaluadores
  ])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (['region'].includes(name)) {
      setForm(prev => ({
        ...prev,
        [name]: value,
        cliente_comuna_id: ''
      }))

      return
    }

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    const productoData = selectFilter.lineasProductos.find(({ id }) => id === idExtractor(form?.producto))

    if (!queryParams?.pedido_id?.length) return dispatch(fireToaster({ title: 'Error al seleccionar el pedido', icon: 'info' }))
    if (!form?.posicion_id?.length ?? form?.posicion_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione una posición', icon: 'info' }))
    if (!form?.lateral_id?.length ?? form?.lateral_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un lateral', icon: 'info' }))

    // if (!form?.producto?.length ?? form?.producto_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un producto', icon: 'info' }))
    // if (!form?.producto_sku?.length ?? form?.producto_sku.includes('*NULL*')) return dispatch(fireToaster({ title: 'Error al seleccionar el SKU del producto', icon: 'info' }))
    // if (!form?.producto_nombre?.length ?? form?.producto_nombre.includes('*NULL*')) return dispatch(fireToaster({ title: 'Error al seleccionar el nombre del producto', icon: 'info' }))
    if (!form?.producto_articulo?.length ?? form?.producto_articulo.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un artículo', icon: 'info' }))
    if (!form?.producto_tipo?.length ?? form?.producto_tipo.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un tipo', icon: 'info' }))
    if (!form?.producto_dot?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un DOT', icon: 'info' }))
    if (!form?.producto_origen?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un origen', icon: 'info' }))

    if (!form?.cliente_nombre?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese el nombre del cliente', icon: 'info' }))
    if (!form?.cliente_email?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese el email del cliente', icon: 'info' }))
    if (!form?.cliente_telefono?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese el teléfono del cliente', icon: 'info' }))

    if (!form?.numero_factura?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un número de factura', icon: 'info' }))
    if (!form?.vehiculo_marca?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese una marca de vehículo', icon: 'info' }))
    if (!form?.vehiculo_modelo?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un modelo de vehículo', icon: 'info' }))
    if (!form?.vehiculo_año) return dispatch(fireToaster({ title: 'Por favor, ingrese el año del vehículo', icon: 'info' }))
    if (!form?.numero_chasis?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un número de chasis', icon: 'info' }))
    if (!form?.patente?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese una patente', icon: 'info' }))

    let url = id => id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl
    let formData

    if (id) {
      formData = {
        pedido_id: Number(queryParams.pedido_id), //?int
        // usuario_id: '', //?int

        posicion_id: idExtractor(form?.posicion_id) ?? null, //?str
        lateral_id: idExtractor(form?.lateral_id) ?? null, //?str 

        estado_id: idExtractor(form?.estado_id) ?? null, //?int 
        daño_id: idExtractor(form?.daño_id) ?? null, //?int 
        daño_dos_id: idExtractor(form?.daño_dos_id) ?? null, //?int 
        daño_tres_id: idExtractor(form?.daño_tres_id) ?? null, //?int 
        causa_id: idExtractor(form?.causa_id) ?? null, //int 
        evaluador_id: idExtractor(form?.evaluador_id) ?? null, //int 
        resultado_id: idExtractor(form?.resultado_id) ?? null, //?int 
        respuesta_id: idExtractor(form?.respuesta_id) ?? null, //?int 

        patente: form?.patente ?? null, //?str
        vehiculo_marca: form?.vehiculo_marca ?? null, //?str
        vehiculo_modelo: form?.vehiculo_modelo ?? null, //?str
        vehiculo_año: Number(form?.vehiculo_año) ?? null, //?int
        numero_chasis: form?.numero_chasis ?? null, //?str

        producto_sku: form?.producto_sku ?? null, //?str
        producto_nombre: form?.producto_nombre ?? null, //?str
        producto_articulo: form?.producto_articulo ?? null, //?str
        producto_tipo: form?.producto_tipo ?? null, //?str
        producto_dot: form?.producto_dot ?? null, //?str
        producto_origen: form?.producto_origen ?? null, //?str

        cliente_nombre: form?.cliente_nombre ?? null, //?str
        cliente_rut: form?.cliente_rut ?? null, //?str
        cliente_email: form?.cliente_email ?? null, //?str
        cliente_telefono: form?.cliente_telefono ?? null, //?str
        cliente_domicilio: form?.cliente_domicilio ?? null, //?str
        cliente_codigo_postal: form?.cliente_codigo_postal ? Number(form?.cliente_codigo_postal) : null, //?int
        cliente_comuna_id: idExtractor(form?.cliente_comuna_id) ?? null, //?int

        numero_factura: Number(form?.numero_factura) ?? null, //?int
        comentario: form?.comentario ?? null, //?str
        averia_uno: form?.averia_uno ?? null, //?str 
        averia_dos: form?.averia_dos ?? null, //?str 
        banda_residual: form?.banda_residual ?? null, //?str
        porcentaje_bonificacion: Number(form?.porcentaje_bonificacion) ?? null, //?int 
        envio_fabrica: form?.envio_fabrica ? true : false ?? null, //?bool 
        codigo_evaluador: form?.codigo_evaluador ?? null, //?str 
        observacion: form?.observacion ?? null, //?str 

        fecha_creacion: form?.fecha_creacion ?? null, //?str 
        fecha_recepcion: form?.fecha_recepcion ?? null, //?str 
        fecha_envio: form?.fecha_envio ?? null, //?str 
      }
    }
    else {
      formData = new FormData()
      formData.append('pedido_id', queryParams?.pedido_id)
      formData.append('posicion_id', idExtractor(form?.posicion_id))
      formData.append('lateral_id', idExtractor(form?.lateral_id))
      formData.append('daño_id', idExtractor(form?.daño_id))
      form?.daño_dos_id && formData.append('daño_dos_id', idExtractor(form?.daño_dos_id))
      form?.daño_tres_id && formData.append('daño_tres_id', idExtractor(form?.daño_tres_id))

      !id && formData.append('producto_id', productoData.id)
      formData.append('producto_sku', productoData?.sku ?? form?.producto_sku)
      formData.append('producto_nombre', productoData?.nombre ?? form?.producto_nombre)
      formData.append('producto_articulo', form?.producto_articulo?.split('-')?.[0])
      formData.append('producto_tipo', form?.producto_tipo?.split('-')?.[0])
      formData.append('producto_dot', form?.producto_dot)
      formData.append('producto_origen', form?.producto_origen)

      formData.append('cliente_nombre', form?.cliente_nombre)
      formData.append('cliente_rut', form?.cliente_rut)
      formData.append('cliente_email', form?.cliente_email)
      formData.append('cliente_telefono', form?.cliente_telefono)
      form?.cliente_domicilio?.length && formData.append('cliente_domicilio', form?.cliente_domicilio)
      form?.cliente_comuna_id?.length && !form?.cliente_comuna_id.includes('NULL') && formData.append('cliente_comuna_id', idExtractor(form?.cliente_comuna_id))
      form?.cliente_codigo_postal?.length && formData.append('cliente_codigo_postal', form?.cliente_codigo_postal)

      formData.append('numero_factura', form?.numero_factura)
      formData.append('vehiculo_marca', form?.vehiculo_marca)
      formData.append('vehiculo_modelo', form?.vehiculo_modelo)
      formData.append('vehiculo_año', form?.vehiculo_año)
      formData.append('numero_chasis', form?.numero_chasis)

      formData.append('patente', form?.patente)
      formData.append('comentario', form?.comentario)

      evidencias.length &&
        evidencias.forEach(({ file }) => formData.append('evidencias[]', file))
    }

    // TODO: probar integracion
    // for (const [k, v] of formData) {
    //   console.log(k, v);
    // }
    // return

    setIsConsulting(true)

    await axios(url(id), {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-type': id ? 'application/json' : 'multipart/form-data'
      },
      data: formData
    })
      .then((res) => {
        dispatch(fireToaster(responseToasterContent(res)))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }


  return (
    <div className="row">
      <div className="col col-md-12 mx-auto">
        <div className='row'>
          <Button text={CONSTANT.title}
            className='col-6'
            icon='ArrowLeft'
            style='flat'
            isLink
            url={CONSTANT.redirectUrl}
          />

          <Button
            text={`Volver al pedido #${queryParams?.pedido_id}`}
            icon='File'
            style='flat'
            isLink
            url={`/pedidos/detalle/${queryParams?.pedido_id}`}
            className='col-6'
          />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {isConsulting &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={<>{CONSTANT.componentTitle(id)} </>}>
          <div className="row">
            {/* MARK: NUEVA INTEGRACION */}
            <span className="text-end sub-text-2">Número de seguimiento: <b>{form.numero_seguimiento}</b></span>

            {/* MARK: DATOS PRODUCTO */}
            <div className="col-12 col-md-6">
              <div className="row">
                <hr className="my-2" />
                <h3>DATOS PRODUCTO</h3>

                {id
                  ? <>
                    <FormInput
                      labelText='* Nombre'
                      name='producto_nombre'
                      value={form?.producto_nombre ?? ''}
                      handleValue={handleInputChange}
                      size='col-12'
                      sizeDesk='col-md-9'
                    />

                    <FormInput
                      labelText='* SKU'
                      name='producto_sku'
                      value={form?.producto_sku ?? ''}
                      handleValue={handleInputChange}
                      size='col-12'
                      sizeDesk='col-md-3'
                    />
                  </>

                  : <>
                    <SelectFilter
                      labelText='* Producto'
                      name='producto'
                      value={form?.producto?.split('-')?.[1] ?? 'Seleccione'}
                      handleValue={handleInputChange}
                      size='col-12'
                      sizeDesk='col-md-12'
                      optionObj={selectFilter?.lineasProductos?.map(({ id, nombre, sku }) => ({ id, name: `${sku} | ${nombre}` }))}
                    />
                  </>
                }

                <SelectFilter
                  labelText='* Zona de daño'
                  name='lateral_id'
                  value={form?.lateral_id?.split('-')?.[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                  optionObj={selectFilter?.laterales?.map(({ id, lateral: name }) => ({ id, name }))}
                />

                <SelectFilter
                  labelText='* Posición'
                  name='posicion_id'
                  value={form?.posicion_id?.split('-')?.[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                  optionObj={selectFilter?.posiciones?.map(({ id, posicion: name }) => ({ id, name }))}
                />

                <SelectFilter
                  labelText='* Artículo'
                  name='producto_articulo'
                  value={form?.producto_articulo?.split('-')?.[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                  optionObj={selectFilter?.productoArticulos?.map(({ id, nombre }) => ({ id, name: nombre }))}
                />

                <SelectFilter
                  labelText='* Tipo'
                  name='producto_tipo'
                  value={form?.producto_tipo?.split('-')?.[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                  optionObj={selectFilter?.productoTipo?.map(({ id, nombre }) => ({ id, name: nombre }))}
                />

                <FormInput
                  labelText='* DOT'
                  name='producto_dot'
                  value={form?.producto_dot ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Origen'
                  name='producto_origen'
                  value={form?.producto_origen ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />
              </div>
            </div>

            {/* MARK: DATOS CLIENTE */}
            <div className="col-12 col-md-6 px-4">
              <div className="row">
                <hr className="my-2" />
                <h3>DATOS CLIENTE</h3>
                <FormInput
                  labelText='* Nombre'
                  name='cliente_nombre'
                  value={form?.cliente_nombre ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* RUT'
                  name='cliente_rut'
                  value={form?.cliente_rut ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Email'
                  name='cliente_email'
                  value={form?.cliente_email ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Teléfono'
                  name='cliente_telefono'
                  value={form?.cliente_telefono ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='Domicilio'
                  name='cliente_domicilio'
                  value={form?.cliente_domicilio ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <SelectFilter
                  labelText='Región'
                  name='region'
                  value={form?.region?.split('-')?.[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                  optionObj={selectFilter?.regiones?.map(({ id, nombre }) => ({ id, name: nombre }))}
                />

                {form?.region.length && !form?.region.includes('NULL')
                  ? <SelectFilter
                    labelText='Comuna'
                    name='cliente_comuna_id'
                    value={form?.cliente_comuna_id?.split('-')?.[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    size='col-12'
                    sizeDesk='col-md-6'
                    optionObj={selectFilter?.comunas?.map(({ id, nombre }) => ({ id, name: nombre }))}
                  />

                  : <FormInput
                    labelText='Comuna'
                    value={''}
                    isDisabled
                    size='col-12'
                    sizeDesk='col-md-6'
                  />
                }

                <FormInput
                  labelText='Cod. Postal'
                  name='cliente_codigo_postal'
                  value={form?.cliente_codigo_postal ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />
              </div>
            </div>

            {/* MARK: DATOS VEHÍCULO */}
            <div className="col-12 col-md-6">
              <div className="row">
                <hr className="my-2" />
                <h3>DATOS VEHÍCULO</h3>

                <FormInput
                  labelText='* Marca'
                  name='vehiculo_marca'
                  value={form?.vehiculo_marca ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Modelo'
                  name='vehiculo_modelo'
                  value={form?.vehiculo_modelo ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Año'
                  type='number'
                  name='vehiculo_año'
                  value={form?.vehiculo_año ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Chasis'
                  name='numero_chasis'
                  value={form?.numero_chasis ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <FormInput
                  labelText='* Patente (Placa)'
                  name='patente'
                  value={form?.patente ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />
              </div>
            </div>

            {/* MARK: DATOS GENERALES */}
            <div className="col-12 col-md-6 px-4">
              <div className="row">
                <hr className="my-2" />
                <h3>DATOS GENERALES</h3>

                <FormInput
                  labelText='* Número factura'
                  name='numero_factura'
                  value={form?.numero_factura ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                <SelectFilter
                  labelText="* Daño 1"
                  name="daño_id"
                  value={form?.daño_id?.split('-')[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  optionObj={selectFilter?.daños?.map(({ daño, nombre, id }) => ({ name: nombre ?? daño, id }))}
                  size="col-12"
                  sizeDesk='col-md-6'
                />

                <SelectFilter
                  labelText="Daño 2"
                  name="daño_dos_id"
                  value={form?.daño_dos_id?.split('-')[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  optionObj={selectFilter?.daños?.map(({ daño, nombre, id }) => ({ name: nombre ?? daño, id }))}
                  size="col-12"
                  sizeDesk='col-md-6'
                />

                <SelectFilter
                  labelText="Daño 3"
                  name="daño_tres_id"
                  value={form?.daño_tres_id?.split('-')[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  optionObj={selectFilter?.daños?.map(({ daño, nombre, id }) => ({ name: nombre ?? daño, id }))}
                  size="col-12"
                  sizeDesk='col-md-6'
                />

                <TextareaInput
                  labelText='* Descripción de los hechos'
                  name='comentario'
                  value={form?.comentario ?? ''}
                  handleValue={handleInputChange}
                  size='col-12'
                  sizeDesk='col-md-12'
                  rows={2}
                />

                {!id &&
                  <FileUploaderB handleValue={setEvidencias} value={evidencias} labelText='* Evidencias' />
                }
              </div>
            </div>

            {/* MARK: DATOS ADICIONALES */}
            {id &&
              <div className="col-12 col-md-12 px-4">
                <div className="row">
                  <hr className="my-2" />

                  <h3>DATOS ADICIONALES</h3>

                  <div className='d-flex justify-content-end py-1'>
                    <CheckBoxInput
                      labelText="Envío a fábrica"
                      name="envio_fabrica"
                      value={form?.envio_fabrica}
                      handleValue={handleInputChange}
                      size="col-12"
                      sizeDesk='col-md-6'
                    />
                  </div>

                  <SelectFilter
                    labelText="* Resultado"
                    name="resultado_id"
                    value={form?.resultado_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter.resultados?.map(({ resultado, nombre, id }) => ({ name: resultado ?? nombre, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <SelectFilter
                    labelText="* Respuesta"
                    name="respuesta_id"
                    value={form?.respuesta_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter.respuestas?.map(({ titulo, nombre, id }) => ({ name: titulo ?? nombre, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <SelectFilter
                    labelText="* Daño"
                    name="daño_id"
                    value={form?.daño_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter?.daños?.map(({ daño, nombre, id }) => ({ name: nombre ?? daño, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <SelectFilter
                    labelText="* Evaluador"
                    name="evaluador_id"
                    value={form?.evaluador_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter?.evaluadores?.map(({ nombre, id }) => ({ name: nombre, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <SelectFilter
                    labelText="* Causa"
                    name="causa_id"
                    value={form?.causa_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter?.causas?.map(({ causa, nombre, id }) => ({ name: nombre ?? causa, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <SelectFilter
                    labelText="* Conclusión"
                    name="conclusion_id"
                    value={form?.conclusion_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter.conclusiones?.map(({ titulo, nombre, id }) => ({ name: titulo ?? nombre, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <SelectFilter
                    labelText="* Recomendación"
                    name="recomendacion_id"
                    value={form?.recomendacion_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputChange}
                    optionObj={selectFilter.recomendaciones?.map(({ titulo, nombre, id }) => ({ name: titulo ?? nombre, id }))}
                    size="col-12"
                    sizeDesk='col-md-3'
                  />

                  <FormInput
                    labelText="Banda residual"
                    name='banda_residual'
                    handleValue={handleInputChange}
                    value={form?.banda_residual}
                    size="col-12"
                    sizeDesk="col-md-3"
                    placeholder='Banda residual'
                  />

                  <FormInput
                    labelText="% Bonificación"
                    name='porcentaje_bonificacion'
                    handleValue={handleInputChange}
                    value={form?.porcentaje_bonificacion}
                    size="col-12"
                    sizeDesk="col-md-2"
                    type='number'
                    placeholder='Porcentaje'
                  />

                  <FormInput
                    labelText="Código del evaluador"
                    name='codigo_evaluador'
                    handleValue={handleInputChange}
                    value={form?.codigo_evaluador}
                    size="col-12"
                    sizeDesk="col-md-2"
                    placeholder='Código del evaluador'
                  />

                  <FormInput
                    labelText="* Recepción de neumático"
                    name='fecha_recepcion'
                    type='date'
                    handleValue={handleInputChange}
                    value={form?.fecha_recepcion}
                    size="col-12"
                    sizeDesk="col-md-2"
                  />

                  <FormInput
                    labelText="* Envío de neumático"
                    name='fecha_envio'
                    type='date'
                    handleValue={handleInputChange}
                    value={form?.fecha_envio}
                    size="col-12"
                    sizeDesk="col-md-2"
                  />

                  {/* <TextareaInput
                    labelText='Observaciones'
                    name='observacion'
                    placeholder='Escriba sus observaciones...'
                    value={form?.observacion ?? ''}
                    handleValue={handleInputChange}
                    sizeDesk="col-md-4"
                  /> */}
                </div>
              </div>
            }
          </div>

          <div className="d-flex justify-content-center mt-2">
            <Button
              type="submit"
              text={id ? 'Actualizar garantía' : "Crear garantía"}
              color="success"
              disabled={isConsulting}
              icon={'Check'}
              onClick={(e) => handleFetch(e)}
            />
          </div>
        </CustomForm>
      </div>
    </div>
  );
};

export default Form;
