export class PrecioFormatter {
  constructor(value) {
    this.rawValue = value
    this.value = Math.round(Number(value))
  }
  producto(raw = false) {
    if (this.value === 0 && !raw) return '-'

    if (isNaN(this.value)) {
      console.warn(`El valor "${this.rawValue} (${typeof this.rawValue})" no puede ser convertido a un número`)
      return '-'
    }

    const arr = String(this.value).split('').reverse()
    let newValue = []
    let split = 1

    for (let i = 0; i < arr.length; i++) {
      newValue.push(arr[i])

      if (split % 3 === 0 && split !== arr.length) {
        newValue.push('.')
      }

      split++
    }

    return `$ ${newValue.reverse().join('')}`
  }
}
