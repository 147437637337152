// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterReportePedidosCotizaciones, resultadosReportePedidosCotizaciones } from 'redux/actions/reportePedidosCotizaciones';


// Components
import Table from "../Table";


// Services
import { getAllReportePedidosCotizaciones } from 'services/reportePedidosCotizaciones/getAll.service';


// Layouts
import AccordionTable from "layout/AccordionTable";
import { Link } from 'react-router-dom';


const CONSTANT = {
	permissionsPath: 'pedidos',
	pathUrl: '/reporte-pedidos-cotizaciones',
	title: 'Reporte | Pedidos - Cotizaciones',
	reduxClearFilters: clearFilterReportePedidosCotizaciones,
	reduxSetResults: resultadosReportePedidosCotizaciones,

	reduxStateSelector: 'reportePedidosCotizaciones',
	principalService: getAllReportePedidosCotizaciones
}


export const ReportePedidosCotizacionesTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	// const handleUrl = (url) => {
	// 	dispatch(CONSTANT.reduxSetResults(null))

	// 	CONSTANT.principalService(showing_results, filtros, url)
	// 		.then(res => {
	// 			dispatch(CONSTANT.reduxSetResults(res))
	// 		})
	// 		.catch(err => {
	// 			console.error(err);
	// 			dispatch(CONSTANT.reduxSetResults([]))
	// 		})
	// }


	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				<Table response={response} >
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID pedido</th>
							<th>ID cotización</th>
							<th>Cot. creada después de pedido</th>
							<th>Ejecutivo</th>
							<th>Fecha/Hora pedido</th>
							<th>Fecha/Hora Cot.</th>
							<th>Dif. horas</th>
							<th>Dif. min.</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data, i) => {
								return (
									<tr className="odd" key={`report-${i}-${data?.pedido_id}-${data.fecha_pedido}`}>
										<td className='col-1'>
											{data?.pedido_id
												? <Link to={`/pedidos/detalle/${data?.pedido_id}`} target='_blank' rel='noreferrer'>
													{data?.pedido_id ?? '-'}
												</Link>

												: '-'
											}
										</td>

										<td className='col-1'>
											{data?.cotizacion_id
												? <Link to={`/cotizaciones/detalle/${data?.cotizacion_id}`} target='_blank' rel='noreferrer'>
													{data?.cotizacion_id ?? '-'}
												</Link>

												: '-'
											}
										</td>

										<td className='col-1'>{data?.cotizacion_despues_pedido ?? '-'}</td>
										<td className='col-1'>{data?.ejecutivo ?? '-'}</td>
										<td className='col-1'>{data?.fecha_pedido ?? '-'}</td>
										<td className='col-1'>{data?.fecha_cotizacion ?? '-'}</td>
										<td className='col-1'>{data?.diferencia_tiempo_horas ?? '-'}</td>
										<td className='col-1'>{data?.diferencia_tiempo_minutos ?? '-'}</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}