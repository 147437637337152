import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'


// Components


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers | Services
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { idExtractor } from '../../helpers/idExtractor'
import { getAllEmpresasClasificaciones } from 'services/empresasClasificaciones/getAll.service'
import { responseToasterContent } from 'helpers/toasterContent'
import { clearFilterEmpresasClasificaciones, resultadosEmpresasClasificaciones } from 'redux/actions/empresasClasificaciones'


const CONSTANT = {
  redirectUrl: '/empresas-clasificaciones',
  reduxStateSelector: 'empresasClasificaciones',
  title: 'Búsqueda de clasificaciones de empresas',

  servicePrincipal: getAllEmpresasClasificaciones,

  reduxClearFilters: clearFilterEmpresasClasificaciones,
  reduxSetResults: resultadosEmpresasClasificaciones,
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (k === `tipo_id` && v.length && !v.includes('NULL')) {
      validFormat = {
        ...validFormat,
        [k]: idExtractor(v, true)
      }
    }

    if (['busqueda', 'busqueda_usuario'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }
  })

  return validFormat
}

export const EmpresasClasificacionesFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    setIsConsulting(true)

    CONSTANT.servicePrincipal(showing_results)
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        console.error(err);
      })

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return CONSTANT.servicePrincipal(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
    }

    setIsConsulting(true)

    await CONSTANT.servicePrincipal(showing_results, filtrosFormatter(filtros))
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
        dispatch(CONSTANT.reduxSetResults([]))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          {/* <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          /> */}
        </>
      </ListadoHeader>
    </div>
  )
}