import Table from 'components/Table'
import AccordionTable from 'layout/AccordionTable'

const EmpresasAsociadas = ({ data }) => {
  return (
    <>
      <div className="row">
        <AccordionTable title={`Empresas asociadas (${data?.empresas_asociadas?.length ?? 0} resultados)`}>
          <Table pb={false}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
              <tr role="row">
                <th>Razón social</th>
                <th>Tipo empresa</th>
                <th>Teléfono</th>
                <th>RUT</th>
              </tr>
            </thead>
            <tbody>
              {data?.empresas_asociadas?.length
                ? data?.empresas_asociadas.map((data) => {
                  return (
                    <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                      <td>{data?.razon_social ?? '-'}</td>
                      <td>{data?.empresa_tipo?.nombre ?? '-'}</td>
                      <td>{data?.telefono ?? '-'}</td>
                      <td>{data?.rut ?? '-'}</td>
                    </tr>
                  )
                })
                : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
              }
            </tbody>
          </Table>
        </AccordionTable >
      </div>
    </>
  )
}

export default EmpresasAsociadas