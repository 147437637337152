
// Components
import { EmpresasFiltros } from "../../components/AccordionFilter/Empresas";
// import DropdownMenuLink from "../../components/Dropdown/Link";
// import DropdownWrapper from "../../components/Dropdown/Wrapper";
import { EmpresasTable } from "../../components/Tables/EmpresasTable";

const CONSTANT = {
	title: 'Empresas',
	path: 'empresas',
	create_btn: 'Crear empresa'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				{/* <DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper> */}
			</h3>

			<EmpresasFiltros />

			<EmpresasTable />
		</div>
	);
}
