import Accordion from 'components/Accordion'
import AccordionDetailData from 'components/AccordionDetailData'
import Button from 'components/Button'
import ModalActualizarCredito from 'components/Modal/Empresas/ModalActualizarCredito'
import { PrecioFormatter } from 'helpers/precioFormatter'
import { useState } from 'react'

const price = (v) => new PrecioFormatter(v).producto()

const Informacion = ({ data, cb }) => {
  const [modalCredito, setModalCredito] = useState({ display: false, data: null })

  const handleOpenCreditoModal = () => {
    setModalCredito({ display: true, data: { credito: data?.credito_actual, id: data?.id } })
  }

  const handleCloseCreditoModal = () => {
    setModalCredito({ display: false, data: null })
  }

  return (
    <>
      <ModalActualizarCredito data={modalCredito?.data} handleClose={handleCloseCreditoModal} isOpen={modalCredito?.display} endCb={cb} />

      <Accordion
        title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
        classes='col-md-6 mx-auto mb-2'
        isOpen
      >
        <AccordionDetailData dataKey={'ID'} value={data?.id} />
        <AccordionDetailData dataKey={'Tipo de empresa'} value={data?.tipo_empresa?.toUpperCase() ?? 'Nueva empresa'} />
        <AccordionDetailData dataKey={'Razón social'} value={data?.razon_social ?? 'N/A'} />
        <AccordionDetailData dataKey={'RUT'} value={data?.rut ?? 'N/A'} />
        <AccordionDetailData dataKey={'% Descuento'} value={data?.porcentaje_descuento ?? 'N/A'} />
        <AccordionDetailData dataKey={'% Profit'} value={data?.profit ?? 'N/A'} />
        <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
        <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
      </Accordion>

      <div className='col-6'>
        <Accordion
          title={<h3 className='m-0 w-100 text-center text-white'>Línea de crédito</h3>}
          classes='col-md-12 mx-auto mb-2'
          isOpen
        >
          <AccordionDetailData dataKey={'Línea total'} value={
            <div className='d-flex justify-content-between gap-1 justify-items-center'>
              <span className='my-auto'>
                {price(data?.credito) ?? '-'}
              </span>

              <Button icon={'Settings'} color='warning' className={'p-25'} onClick={handleOpenCreditoModal} />
            </div>
          } />
          <AccordionDetailData dataKey={'Línea disponible'} value={price(data?.credito_actual) ?? '-'} last />
        </Accordion>

        {data?.tiene_datos_factura
          ? <Accordion
            title={<h3 className='m-0 w-100 text-center text-white'>Datos de facturacion</h3>}
            classes='col-md-12 mx-auto mb-2'
            isOpen
          >
            <AccordionDetailData dataKey={'ID'} value={data?.datos_factura?.id} />
            <AccordionDetailData dataKey={'Giro'} value={data?.datos_factura?.empresa_giro ?? 'N/A'} />
            <AccordionDetailData dataKey={'Dirección'} value={data?.datos_factura?.empresa_direccion ?? 'N/A'} />
            <AccordionDetailData dataKey={'Creación'} value={data?.datos_factura?.created_at?.split('T')[0] ?? 'N/A'} />
            <AccordionDetailData dataKey={'Modicación'} value={data?.datos_factura?.updated_at?.split('T')[0] ?? 'N/A'} last />
          </Accordion>

          : <span>Esta empresa aún no tiene datos de facturación</span>
        }
      </div>
    </>
  )
}

export default Informacion