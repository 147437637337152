import { useEffect, useState } from "react"


// Components
import Modal from "components/Modal"
import SelectFilter from "components/SelectFilter"
import Button from "components/Button"

// Redux
import { fireToaster } from "redux/actions/toaster"
import { useDispatch } from "react-redux"


// Helpers
import { responseToasterContent } from "helpers/toasterContent"


// Services
import { getAllProveedoresTransportes } from "services/proveedoresTransportes/getAll.service"
import { updatePedido } from "services/pedidos/update.service"
import { idExtractor } from "helpers/idExtractor"


const CONSTANT = {
  form: {
    proveedor_transporte_id: null,
  },

  selectFilterInitialState: {
    proveedoresTransporte: [],
  },
}


const fetchSelectFilterData = async () => {
  const proveedoresTransporte = await getAllProveedoresTransportes(9999)
    .then(({ data }) => data.data)
    .catch(err => {
      console.error(err)
      return []
    })

  return { proveedoresTransporte }
}


const ModalProveedorTransporte = ({ data, isOpen, handleClose, handleEnd }) => {
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchSelectFilterData().then(res => setSelectFilter(res))
  }, [])

  useEffect(() => {
    setForm(CONSTANT.form)

    return () => {
      setForm(CONSTANT.form)
    }
  }, [isOpen])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!form.proveedor_transporte_id) return dispatch(fireToaster({ title: 'Seleccione un proveedor de transporte', icon: 'info' }))

    const validForm = {
      proveedor_transporte_id: Number(idExtractor(form.proveedor_transporte_id, true))
    }

    await updatePedido(data?.id, { ...validForm })
      .then(res => {
        handleEnd()
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
  }

  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3>Asignar proveedor de transporte</h3>

      <h5 className="mb-2">Pedido #{data?.id}</h5>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <SelectFilter
              labelText="Proveedor de transporte"
              altSeparator
              size="col-12"
              sizeDesk={"col-md-12"}
              optionObj={selectFilter?.proveedoresTransporte?.map(({ id, nombre }) => ({ id, name: nombre }))}
              handleValue={handleInputChange}
              name={'proveedor_transporte_id'}
              value={form?.proveedor_transporte_id?.split('%-%')[1] ?? 'Seleccione'}
            />
          </div>

          <div className="col-12 d-flex justify-content-around mt-2">
            <Button text={`Guardar`} onClick={handleSubmit} color={'success'} />
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalProveedorTransporte