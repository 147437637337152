import axios from "axios"
import { getAll } from "../../endpoints/getAll"


const URL = getAll.empresas_areas_imagenes


export const createEmpresaAreasImagenes = async (data) => {
  try {
    const formData = new FormData()
    formData.append('area_id', data?.area_id)
    data?.imagenes.length && data.imagenes.forEach(i => {
      formData.append('imagenes[]', i.file)
    })

    return await axios(URL, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: formData
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}