import axios from "axios"
import { getOne } from "../../endpoints/getOne"

const URL = (id) => getOne.pedidos(id)

export const getDetailedPedido = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" pedido`)

    const response = await axios(URL(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
      .then(res => res)
      .catch(err => { throw err })

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
