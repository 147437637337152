import { useEffect, useState } from "react"

import { useDispatch } from "react-redux"
import { fireToaster } from "redux/actions/toaster"

import Modal from "components/Modal"
import FormInput from "components/FormInput"
import Button from "components/Button"
import Switch from "components/Switch"

import { updateEmpresas } from "services/empresas/update.service"
import { responseToasterContent } from "helpers/toasterContent"

const CONSTANT = {
  form: {
    credito: null,
    habilitar_clave: false,
    clave_supervisor: ''
  },
}

const ModalActualizarCredito = ({ data, isOpen, handleClose, endCb }) => {
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()

  useEffect(() => {
    setForm(prev => ({
      ...prev, ...data
    }))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [data])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!form.credito) return dispatch(fireToaster({ title: 'Debe enviar un crédito', icon: 'info' }))
    let validForm = { credito: form?.credito }

    if (form?.habilitar_clave) {
      validForm = { ...validForm, clave_supervisor: form?.clave_supervisor }
    }

    await updateEmpresas(data?.id, validForm)
      .then(res => {
        handleClose()
        dispatch(fireToaster(responseToasterContent(res)))
        endCb()
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
  }

  if (!data) return (<></>)

  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3>Editar crédito</h3>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormInput
            labelText='Crédito'
            name={`credito`}
            placeholder='* Ingrese una cantidad'
            size='col-12'
            handleValue={handleInputChange}
            sizeDesk='col-md-6'
            value={form.credito ?? ''}
            type={'number'}
          />

          <Switch
            labelText="Habilitar clave"
            handleValue={handleInputChange}
            value={form?.habilitar_clave}
            name={'habilitar_clave'}
          />

          <FormInput
            labelText='Clave supervisor'
            name={`clave_supervisor`}
            placeholder='Ingrese una clave'
            size='col-12'
            handleValue={handleInputChange}
            sizeDesk='col-md-6'
            value={form.clave_supervisor ?? ''}
            isDisabled={!form?.habilitar_clave}
            type={'number'}
          />

          <div className="col-12 d-flex justify-content-around mt-4">
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} />
            <Button text={`Guardar`} onClick={handleSubmit} color={'success'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalActualizarCredito