import {
   FILTER_CLEAR,
   FILTER_PAGOS_PEDIDOS_INPUT,
   RESULTADOS_PAGOS_PEDIDOS,
} from "../redux-types";

const initialState = {
   filtros: {
      enviado_erp: '',
      fecha_desde: '',
      fecha_hasta: ''
   },
   resultados: null
}


export const pagosPedidosReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_PAGOS_PEDIDOS_INPUT:
         const { key, value } = action.payload

         return {
            ...state, filtros: {
               ...state.filtros,
               [key]: value
            }
         };

      case RESULTADOS_PAGOS_PEDIDOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return { ...state, filtros: { ...initialState.filtros } };

      default:
         return state;
   }
};