import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../views/EmpresasClasificaciones/Listado"
import { Detalle } from "../views/EmpresasClasificaciones/Detalle"
import Form from "../views/EmpresasClasificaciones/Form"

export const EmpresasClasificacionesRouter = () => {
   // const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
