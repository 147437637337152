import { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";


// React-router
import { useNavigate } from "react-router-dom";


// Enpoints
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";


// Helpers
import { idExtractor } from "../../helpers/idExtractor";
import { validateRut } from "../../helpers/validateRut";


const CONSTANT = {
  title: 'Usuarios',
  componentTitle: 'Crear usuario',
  redirectUrl: '/usuarios',
  queryUrl: create.usuarios,
  fecthRoles: getAll.roles,
  fecthVendedores: getAll.erp_vendedores + '/obtener',
  fecthUsuarios: getAll.erp_usuarios,
  fecthPaises: getAll.paises + `/buscar`,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    pais_id: '',
    role_id: '',
    rut: '',
    email: '',
    fono: '',
    nombre: '',
    clave: '',
    porcentaje_descuento_max: '',
    cod_maxi: '*NULL*-Sin selección',
    usuario_erp: '*NULL*-Sin selección',
    confirmar_clave: '',
    clave_supervisor: '',
    estatus: 1,
  },
  selectFilterInitialState: {
    roles: [],
    paises: []
  }
}


const handleSelectFiltersData = async () => {
  const roles = await axios(CONSTANT.fecthRoles,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const paises = await axios(CONSTANT.fecthPaises,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const vendedores = await axios(CONSTANT.fecthVendedores,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const usuarios = await axios(CONSTANT.fecthUsuarios,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { roles, paises, vendedores, usuarios }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    const { patron_identificacion, locale, extension_telefonica } = selectFilter.paises.find((({ id }) => id === idExtractor(form.pais_id)))

    if (form.clave !== form.confirmar_clave) return dispatch(fireToaster({ title: 'Las contraseñas no coinciden', icon: 'info' }))
    if (!form?.rut?.length) return dispatch(fireToaster({ title: 'El RUT es requerido', icon: 'info' }))
    if (!validateRut(form.rut, patron_identificacion)) return dispatch(fireToaster({ title: 'El RUT no es válido para el país seleccionado', icon: 'info' }))
    if (!form?.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es requerido', icon: 'info' }))
    if (!form?.email?.length) return dispatch(fireToaster({ title: 'El email es requerido', icon: 'info' }))
    if (!form?.clave?.length) return dispatch(fireToaster({ title: 'La clave es requerida', icon: 'info' }))
    if (!form?.fono?.length) return dispatch(fireToaster({ title: 'La teléfono es requerido', icon: 'info' }))
    if (!validator.isEmail(form?.email)) return dispatch(fireToaster({ title: 'El formato del email no es válido', icon: 'info' }))
    if (!validator.isMobilePhone(`${extension_telefonica}${form.fono}`, [locale])) return dispatch(fireToaster({ title: 'El teléfono no es válido para el país seleccionado', icon: 'info' }))


    let requestData = {
      pais_id: idExtractor(form.pais_id),
      role_id: idExtractor(form.role_id),
      rut: form.rut,
      email: form.email,
      fono: form.fono,
      nombre: form.nombre,
      clave: form.clave,
      clave_supervisor: form.clave_supervisor?.length ? form.clave_supervisor : null,
      cod_maxi: form.cod_maxi.includes('*NULL*') ? null : String(idExtractor(form.cod_maxi)),
      usuario_erp: form.usuario_erp.includes('*NULL*') ? null : form.usuario_erp.split('-')[0],
      estatus: Number(form.estatus) ? 1 : 0,
    }

    if (form.porcentaje_descuento_max) requestData = { ...requestData, porcentaje_descuento_max: form.porcentaje_descuento_max }

    axios(CONSTANT.queryUrl,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: requestData
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.paises.length && selectFilter.roles.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={form?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <SelectFilter
                labelText="* País"
                name="pais_id"
                value={form?.pais_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.paises?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <SelectFilter
                labelText="* Rol"
                name="role_id"
                value={form?.role_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.roles?.map(({ nombre, name, id }) => ({ name: nombre ?? name, id }))}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <FormInput
                labelText="% Desc. max."
                name='porcentaje_descuento_max'
                type='number'
                placeholder='10%'
                handleValue={handleInputChange}
                value={form?.porcentaje_descuento_max}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <FormInput
                labelText="* Nombre"
                name='nombre'
                placeholder='Nombre...'
                handleValue={handleInputChange}
                value={form?.nombre}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Teléfono"
                placeholder='Núm...'
                name='fono'
                handleValue={handleInputChange}
                value={form?.fono}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="Usuario ERP"
                name="usuario_erp"
                value={form?.usuario_erp?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.usuarios?.map(({ usuario, nombre, codigo, id }) => ({ name: usuario ?? nombre, id: codigo ?? id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="Cod. Maxi (Vendedor)"
                name="cod_maxi"
                value={form?.cod_maxi?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.vendedores?.map(({ nombre, vendedor, codigo_vendedor, id }) => ({ name: nombre ?? vendedor, id: id ?? codigo_vendedor }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Email"
                placeholder='Email...'
                name='email'
                handleValue={handleInputChange}
                value={form?.email}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* RUT"
                placeholder='RUT...'
                name='rut'
                handleValue={handleInputChange}
                value={form?.rut}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Clave"
                placeholder='Clave...'
                name='clave'
                handleValue={handleInputChange}
                value={form?.clave}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Confirmar clave"
                placeholder='Confirmar clave...'
                name='confirmar_clave'
                handleValue={handleInputChange}
                value={form?.confirmar_clave}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="Clave supervisor"
                placeholder='Clave supervisor...'
                name='clave_supervisor'
                handleValue={handleInputChange}
                value={form?.clave_supervisor}
                size="col-12"
                sizeDesk="col-md-6"
              />

            </div>

            <Button type="submit" text="Crear" color="primary" icon="Table" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;
