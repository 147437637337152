import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// Hooks / Helpers
import { useForm } from "../../hooks/useForm";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";


const CONSTANT = {
  redirectUrl: '/generales/estatus-pedido',
  componentTitle: 'Crear estatus de pedido',
  title: 'Estatus de pedido',
  queryUrl: create.estatus_pedido,
}


const initialformState = {
  nombre: '',
  paso: undefined,
  usar_tienda: false,
  usar_b2b: false,
  estatus: true
};

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [{
    nombre,
    usar_tienda,
    usar_b2b,
    paso,
    estatus
  }, handleInputChange, reset] = useForm(initialformState);


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!nombre?.length) return dispatch(fireToaster({ title: 'El nombre es requerido', icon: 'info' }))

    let data = {
      nombre,
      paso: Number(paso),
      estatus: Number(estatus) ? true : false,
      usar_tienda: Number(usar_tienda),
      usar_b2b: Number(usar_b2b),
    }

    axios(CONSTANT.queryUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json'
        },
        data
      })
      .then(({ data }) => {
        reset()

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch
              labelText='Estatus'
              name='estatus'
              value={estatus}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-4'
            />

            <Switch
              labelText='Usar en tienda'
              name='usar_tienda'
              value={usar_tienda}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-4'
            />

            <Switch
              labelText='Usar en B2B'
              name='usar_b2b'
              value={usar_b2b}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-4'
            />

            <FormInput
              labelText='* Nombre'
              name='nombre'
              value={nombre}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-10'
            />

            <FormInput
              type="number"
              labelText='Num. paso'
              name='paso'
              value={paso}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-2'
            />
          </div>

          <Button
            type="submit"
            text="Crear"
            color="primary"
            icon="Plus"
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div >
    </div >
  );
};

export default Create;
