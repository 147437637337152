import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Table from '../../components/Table'
import { NoResults } from '../../components/Tables/Misc/NoResults'
import CustomLoader from '../../components/CustomLoader'
import DropdownWrapper from '../../components/Dropdown/Wrapper'
import DropdownMenuLink from '../../components/Dropdown/Link'


// Layouts
import AccordionTable from '../../layout/AccordionTable'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { BASE_URL, PATH_URL } from '../../endpoints'


// Helpers
import { todayFormat } from '../../helpers/todayInputFormat'
import { idExtractor } from '../../helpers/idExtractor'
import { getAll } from '../../endpoints/getAll'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { historicoData } from '../../helpers/historicoData'
import { ModalTracking } from '../../components/Modal/NotasVentas/ModalTracking'
import { ModalPlanificacion } from '../../components/Modal/NotasVentas/ModalPlanificacion'
import { ModalOrdenCompra } from '../../components/Modal/NotasVentas/ModalOrdenCompra'


const CONSTANT = {
	queryUrlGetOne: getOne.notas_ventas,
	title: 'Notas de venta',
	redirectListado: '/erp/notas-ventas',
	modelo: 'NotaVenta',

	fetchCredenciales: (id) => BASE_URL + PATH_URL + `/erp/dxc/factura/url-descarga/${id}`,
	fecthProductosOc: BASE_URL + PATH_URL + '/erp/productos?ligero=1',
	fetchAgendamientos: getAll.agendamientos + '/listar?desde=2020-01-01&hasta=2031-01-01',
	fetchBodegas: BASE_URL + PATH_URL + '/erp/bodegas/obtener-vigentes',
	fetchProveedores: BASE_URL + PATH_URL + '/erp/proveedores/obtener',
	fetchNvPdf: (nv) => `${BASE_URL}${PATH_URL}/erp/notas-ventas/${nv}/pdf`,

	urlCreateTracking: (id) => BASE_URL + PATH_URL + `/erp/notas-ventas/tracking/${id}`,
	createPlanificacion: BASE_URL + PATH_URL + `/erp/notas-ventas/planificar`,
	createOC: BASE_URL + PATH_URL + `/erp/ordenes-compras/crear-desde-nv`,

	tiposPagos: {
		E: 'Efectivo',
		C: 'Cheque',
		T: 'Tarjeta de crédito',
		D: 'Tarjeta de dédito',
		N: 'Convenio',
		P: 'Cupón descuento',
		R: 'Cuenta corriente personal',
		W: 'Recaudador externo',
		Y: 'Depósito directo',
		B: 'Transferencia bancaria',
		V: 'Vale vista',
		1: 'Financiera',
		2: 'WebPay',
		default: 'Desconocido'
	},

	planificacionForm: { nota_venta: '', fecha_planificacion: todayFormat(), clave_supervisor: '', habilitar: '' },

	OCForm: {
		nota_venta: '',
		rut_proveedor: '',
		bodega: '',
		comentario: '',
		productos: [],
		extra_productos: []
	},

	selectFilter: {
		bodegas: [],
		proveedores: [],
		ocProductos: []
	},

	trackingForm: {
		imagenes: [],
		rut_proveedor: '',
		email_cliente: '',
		enviar_correo_cliente: 1,
		enviar_oc: 1,
		bodega: '',
		comentario: '',
		items: [],
		extra_productos: []
	}
}


const fetchSelectFiltersData = async () => {
	const ocProductos = await axios(CONSTANT.fecthProductosOc,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data.data)
		.catch(err => {
			console.error(err)
			return []
		})

	const bodegas = await axios(CONSTANT.fetchBodegas,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data?.data ?? [])
		.catch(err => {
			console.error(err)
			return []
		})

	const proveedores = await axios(CONSTANT.fetchProveedores,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data?.data ?? [])
		.catch(err => {
			console.error(err)
			return []
		})

	return { proveedores, bodegas, ocProductos }
}


const fetchSecondaryDataNotaVenta = async (NV) => {
	const dataAgendamiento = Number(NV?.detalle_nv?.EXT_NUM)

	const agendamientos = await axios(CONSTANT.fetchAgendamientos, {
		headers: {
			'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
		},
		method: 'POST',
		data: {
			filtros: {
				pedido_id: [dataAgendamiento]
			}
		}
	})
		.then(({ data }) => {
			return NV = {
				...NV,
				agenda: data ?? null
			}
		})
		.catch(err => {
			console.error(err)
			return NV
		})

	return agendamientos
}



const Detalle = () => {
	const redirect = useNavigate()
	const dispatch = useDispatch()
	const [modal, setModal] = useState({ display: false, target: '' })
	const [displayAllAccordion, setDisplayAllAccodion] = useState(true)
	const [data, setData] = useState(null)
	const [tracking, setTracking] = useState([])
	const [evidencia, setEvidencia] = useState([])
	const [trackingForm, setTrackingForm] = useState(CONSTANT.trackingForm)
	const [trackingImg, setTrackingImg] = useState([])
	const [iframeData, setIframeData] = useState(undefined)
	const { id } = useParams()
	const [OCForm, setOCForm] = useState(CONSTANT.OCForm)
	const [planificacionForm, setPlanificacionForm] = useState(CONSTANT.planificacionForm)
	const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
	const [historico, setHistorico] = useState(null)
	const [modalHistorico, setModalHistorico] = useState({ id: null, display: false, data: null })
	const { user } = useSelector(state => state.auth)


	// EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO Y DE LOS SELECTS PARA NOTA VENTA
	useEffect(() => {
		axios(CONSTANT.queryUrlGetOne(id),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(({ data }) => {
				setTracking(data?.detalle_nv?.UD_IMG_TRACKING_1?.split(',') ?? [])
				setEvidencia(data?.detalle_nv?.UD_IMG_CIERRE?.split(',') ?? [])
				fetchSecondaryDataNotaVenta(data).then(res => setData(res))

				historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
			})
			.catch(err => {
				console.error(err)

				const toasterContent = {
					title: `${err.code} - ${err.response.status}`,
					text: `${err?.response?.data?.errores?.error}`,
					icon: 'warning',
					timer: 3000
				}

				dispatch(fireToaster(toasterContent))

				redirect(CONSTANT.redirectListado)
			})
		return () => setData(null)
	}, [id, dispatch, redirect])


	// EFECTO QUE SETEA POR DEFECTO LOS VALORES DEL FORMULARIO DE TRACKING
	useEffect(() => {
		if (!selectFilter.bodegas.length || !selectFilter.proveedores.length) return
		const defaultBodega = selectFilter.bodegas.find(({ WARE_CODE }) => Number(WARE_CODE) === 8)
		const defaultProveedor = selectFilter.proveedores.find(({ rut_proveedor }) => String(rut_proveedor) === String(data?.detalle_nv?.SHIP_CODE))
		setTrackingForm(trackingForm => ({
			...trackingForm,
			bodega: `${defaultBodega.WARE_CODE}%-%${defaultBodega.WARE_CODE} | ${defaultBodega.DESC_TEXT}`,
			rut_proveedor: `${defaultProveedor?.rut_proveedor ?? '*NULL*'}%-%${defaultProveedor?.rut_proveedor ?? 'N/A'} | ${defaultProveedor?.nombre_proveedor ?? 'Sin selección'}`
		}))
	}, [selectFilter.bodegas, selectFilter.proveedores, data?.detalle_nv?.SHIP_CODE])


	// EFFECTO QUE SETEA LA DATA DE LOS SELECTFILTER
	useEffect(() => {
		fetchSelectFiltersData().then(data => setSelectFilter(data))
	}, [])


	const handleInputChange = (e) => {
		const { name, value } = e.target

		if (name.includes('tracking')) {
			const target = name.split('%-%')?.[1]

			if (target === 'producto') {
				const lineaItem = name.split('%-%')[2]
				const key = name.split('%-%')[3]

				setTrackingForm({
					...trackingForm,
					items: trackingForm.items.map((prod) => prod.linea_item === lineaItem ? { ...prod, [key]: value } : prod)
				})

				return
			}

			if (target === 'extra_productos') {
				const key = name.split('%-%')[3]
				const sku = name.split('%-%')[2]

				if (key === 'add') {
					const newProd = {
						linea_item: 0,
						sku,
						costo_neto: 0,
						qty: 0
					}

					const isListed = trackingForm.extra_productos.find(({ sku }) => sku === newProd.sku)

					return (isListed)
						? dispatch(fireToaster({ title: 'Producto ya incluído', icon: 'info' }))
						: setTrackingForm({ ...trackingForm, extra_productos: [...trackingForm.extra_productos, newProd] })
				}

				if (key === 'delete') {
					return setTrackingForm({
						...trackingForm, extra_productos: trackingForm.extra_productos.filter((prod) => prod.sku !== sku)
					})
				}

				else return setTrackingForm({
					...trackingForm,
					extra_productos: trackingForm.extra_productos.map((prod) => prod.sku === sku ? { ...prod, [key]: value } : prod)
				})
			}

			setTrackingForm({
				...trackingForm,
				[target]: value
			})
		}

		if (name.includes('planificacion')) {
			const key = name.split('%-%')[1]
			setPlanificacionForm({
				...planificacionForm,
				[key]: value
			})
		}

		if (name.includes('OC')) {
			const target = name.split('%-%')?.[1]

			if (target === 'producto') {
				const lineaItem = name.split('%-%')[2]
				const key = name.split('%-%')[3]

				setOCForm({
					...OCForm,
					productos: OCForm.productos.map((prod) => prod.linea_item === lineaItem ? { ...prod, [key]: value } : prod)
				})

				return
			}

			if (target === 'extra_productos') {
				const key = name.split('%-%')[3]
				const sku = name.split('%-%')[2]

				if (key === 'add') {
					const newProd = {
						linea_item: 0,
						sku,
						costo_neto: 0,
						qty: 0
					}

					const isListed = OCForm.extra_productos.find(({ sku }) => sku === newProd.sku)

					return (isListed)
						? dispatch(fireToaster({ title: 'Producto ya incluído', icon: 'info' }))
						: setOCForm({ ...OCForm, extra_productos: [...OCForm.extra_productos, newProd] })
				}

				if (key === 'delete') {
					return setOCForm({
						...OCForm, extra_productos: OCForm.extra_productos.filter((prod) => prod.sku !== sku)
					})
				}

				else return setOCForm({
					...OCForm,
					extra_productos: OCForm.extra_productos.map((prod) => prod.sku === sku ? { ...prod, [key]: value } : prod)
				})
			}

			else return setOCForm({
				...OCForm,
				[target]: value
			})
		}
	}


	const handleModalData = (data, target) => {
		if (target === 'planificacion') {
			setPlanificacionForm({
				...planificacionForm,
				nota_venta: Number(id)
			})
			setModal({ target: 'planificacion', display: true })
		}

		if (target === 'OC') {
			const lineasNvFiltered = data?.detalle_nv?.LINEAS_NV?.filter(({ qty_reservada }) => Number(qty_reservada))
			const defaultBodega = selectFilter.bodegas.find(({ WARE_CODE }) => WARE_CODE === lineasNvFiltered[0]?.bodega)

			setOCForm({
				...OCForm,
				nota_venta: data?.ORDER_NUM,
				bodega: `${defaultBodega?.WARE_CODE ?? '*NULL*'}-${defaultBodega?.WARE_CODE ?? 'N/A'} | ${defaultBodega?.DESC_TEXT ?? '*Sin selección*'}`,
				rut_proveedor: data?.CLIENTE_RUT ?? '',
				productos: data?.detalle_nv?.LINEAS_NV.filter(({ qty_pendiente }) => Number(qty_pendiente) > 0)
					.map(producto => ({
						check: true,
						descripcion: producto?.descripcion ?? 'Error',
						linea_item: producto?.linea_item ?? '',
						sku: producto?.sku ?? '',
						costo_neto: 0,
						qty: Number(producto?.qty_pedida ?? 0),
						qty_reservada: Number(producto?.qty_reservada),
						qty_pendiente: Number(producto?.qty_pendiente)
					}))
			})
			setModal({ target: 'OC', display: true })
		}

		if (target === 'tracking') {
			setTrackingForm({
				...trackingForm,
				email_cliente: data?.detalle_nv?.CLIENTE_EMAIL ?? '',
				items: data?.lineas_nv.map(producto => ({
					check: producto?.PART_CODE === 'SERV-0001',
					descripcion: producto?.DESC_TEXT ?? 'Error',
					linea_item: producto?.LINE_NUM ?? '',
					sku: producto?.PART_CODE ?? '',
					costo_neto: 0,
					qty: Number(producto?.ORDER_QTY ?? 0),
				}))
			})
			setModal({ target: 'tracking', display: true })
		}

		if (target === 'img-zoom') {
			setModal({ target: 'img-zoom', display: true, data })
		}
	}


	const handleFetch = async (objData, target) => {
		let url
		let config

		if (target === 'estatus') {
			let newEstatus = objData[target] ? 0 : 1
			url = CONSTANT.queryUrlGetOne(objData.id)
			config = {
				method: 'PUT',
				data: { [target]: newEstatus, _method: 'PUT' }
			}
		}

		if (target === 'tracking') {
			if (trackingForm?.enviar_correo_cliente && !trackingForm.email_cliente.length) return dispatch(fireToaster({ title: 'El correo del cliente es obligatorio', icon: 'info' }))
			const hasItems = trackingForm.items.filter(({ check }) => check).concat(trackingForm.extra_productos).length

			url = CONSTANT.urlCreateTracking(id)

			let dataForm = new FormData()
			trackingImg?.length && trackingImg.forEach(({ file }) => dataForm.append('imagenes[]', file))
			dataForm.append('email_cliente', trackingForm.email_cliente)
			dataForm.append('enviar_correo_cliente', trackingForm.enviar_correo_cliente ? 1 : 0)
			dataForm.append('enviar_oc', trackingForm.enviar_oc ? 1 : 0)
			if (hasItems) {
				dataForm.append('oc[rut_proveedor]', idExtractor(trackingForm.rut_proveedor, true))
				dataForm.append('oc[bodega]', idExtractor(trackingForm.bodega, true))
				dataForm.append('oc[comentario]', trackingForm.comentario)
				trackingForm.items
					.filter(({ check }) => check)
					.concat(trackingForm.extra_productos.map(prod => ({
						sku: prod.sku,
						linea_item: 0,
						costo_neto: prod.costo_neto,
						qty: prod.qty
					})))
					.forEach((item, i) => {
						dataForm.append(`oc[items][${i}][sku]`, item.sku)
						dataForm.append(`oc[items][${i}][qty]`, item.qty)
						dataForm.append(`oc[items][${i}][costo_neto]`, item.costo_neto)
						dataForm.append(`oc[items][${i}][linea_item]`, item.linea_item)
					})
			}

			config = {
				method: 'POST',
				data: dataForm
			}
		}

		if (target === 'download') {
			url = BASE_URL + 'common/v1/descargas?link=' + objData
			config = {
				method: 'GET'
			}
		}

		if (target === 'planificacion-create') {
			url = CONSTANT.createPlanificacion
			
			const data = {
				...planificacionForm,
				clave_supervisor: planificacionForm?.habilitar ? planificacionForm.clave_supervisor : null
			}

			config = {
				method: 'POST',
				data
			}
		}

		if (target === 'oc-create') {
			url = CONSTANT.createOC
			config = {
				method: 'POST',
				data: {
					nota_venta: Number(id),
					bodega: OCForm.bodega.split('-')[0],
					comentario: OCForm.comentario,
					rut_proveedor: idExtractor(OCForm.rut_proveedor, true),
					productos: OCForm.productos.filter(({ check }) => check)
						.map(el => ({
							linea_item: Number(el.linea_item),
							sku: el.sku,
							costo_neto: Number(el.costo_neto),
							qty: Number(el.qty)
						}))
						.concat(OCForm.extra_productos.map(el => ({
							linea_item: Number(el.linea_item),
							sku: el.sku,
							costo_neto: Number(el.costo_neto),
							qty: Number(el.qty)
						})))
				}
			}

		}

		if (target === 'descargar-factura') {
			url = CONSTANT.fetchCredenciales(objData.id)
		}

		config = {
			...config,
			headers: {
				...config?.headers,
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
			}
		}

		await axios(url, config)
			.then(res => {
				if (target === 'descargar-factura') {
					const url = res?.data
					const secureUrl = 'https://' + url.split('://')[1]
					return window.location.href = secureUrl
				}

				if (target === 'download') {
					setIframeData(BASE_URL + 'common/v1/descargas?link=' + objData)
					return
				}

				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: ${target} actualizado con éxito`,
					icon: 'success'
				}

				dispatch(fireToaster(toasterContent))

				setData(null)
				setModal({ ...modal, display: false })
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
			})

		await axios(CONSTANT.queryUrlGetOne(id),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(({ data }) => {
				setData(data)
				setTracking(data?.detalle_nv?.UD_IMG_TRACKING_1?.split(','))
				historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
			})
			.catch(err => console.error(err))
	}


	// FUNCION MANEJADORA DE LA DATA DEL MODAL
	const handleModalHistorico = (data) => {
		const { id, anterior, nuevo } = data

		setModalHistorico({
			display: true,
			id,
			data: {
				anterior,
				nuevo
			}
		})
	}


	const handleFiltrosPersistentes = (location) => {
		return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
	}

	const zoom = (e) => {
		const zoomer = e.currentTarget
		let offsetX, offsetY, x, y, backgroundPosition
		offsetX = e.nativeEvent.offsetX
		offsetY = e.nativeEvent.offsetY
		x = offsetX / zoomer.offsetWidth * 100
		y = offsetY / zoomer.offsetHeight * 100
		backgroundPosition = `${x}% ${y}%`
		zoomer.style.backgroundPosition = backgroundPosition
	}

	return (
		<>

			<ModalHistorico modal={modalHistorico} fnHandleModal={setModalHistorico} />

			{/* MODAL */}
			<Modal display={modal.display} handleValue={() => setModal({ ...modal, display: false })} sizeDesk='col-md-12'>

				{/* TRACKING */}
				{modal.target === 'tracking' && selectFilter.ocProductos.length &&
					<ModalTracking
						key={'component-tracking-modal'}
						form={trackingForm}
						id={id}
						handleInputChange={handleInputChange}
						selectFilter={selectFilter}
						trackingImg={trackingImg}
						setTrackingImg={setTrackingImg}
						handleFetch={handleFetch}
					/>
				}

				{/* PLANIFICACION */}
				{modal.target === 'planificacion' &&
					<ModalPlanificacion
						key={'component-planificacion-modal'}
						form={planificacionForm}
						id={id}
						handleInputChange={handleInputChange}
						handleFetch={handleFetch}
					/>
				}

				{/* OC */}
				{modal.target === 'OC' &&
					<ModalOrdenCompra
						key={'component-OC-modal'}
						form={OCForm}
						id={id}
						handleInputChange={handleInputChange}
						handleFetch={handleFetch}
						selectFilter={selectFilter}
					/>
				}

				{/*AMPLIAR IMAGEN*/}
				{modal.target === 'img-zoom' &&
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<figure className='zoom' style={{ backgroundImage: 'url(' + modal.data + ')' }} onMouseMove={(ev) => zoom(ev)}>
									<img src={modal.data} alt='imagen' className='img-fluid-zoom' />
								</figure>
							</div>
						</div>
					</div>
				}

			</Modal>

			<iframe title='tracking' src={iframeData} style={{ display: 'none' }}></iframe>

			<div className="card-body p-25 bg-white mb-50 rounded-2 border">

				<div className='d-flex gap-1'>
					<div className='col'>
						<Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
					</div>
				</div>

				<div className="d-flex justify-content-between flex-column flex-md-row mt-0 p-25 align-items-center">
					<b className="mb-0 px-1 fs-3">Nota de venta
						<span className="mx-50 mb-0">#{id}</span>
					</b>

					{data &&
						<div className="d-flex flex-column flex-md-row gap-1">

							{user.permisos[`notas-venta.adjuntar-tracking`] &&
								<Button text='Adjuntar tracking' disabled={!selectFilter?.ocProductos?.length} icon='Plus' color='success' className='p-50' onClick={() => handleModalData(data, 'tracking')} />
							}

							{user.permisos[`ordenes-compra.crear`] &&
								<Button text='Realizar Orden de Compra' disabled={!selectFilter?.proveedores?.length || !selectFilter?.bodegas?.length || !selectFilter?.ocProductos?.length} icon='Plus' color='success' className='p-50' onClick={() => handleModalData(data, 'OC')} />
							}

							{user?.permisos['planificar.crear'] &&
								<Button text='Planificar' icon='Plus' color='success' className='p-50' onClick={() => handleModalData(data, 'planificacion')} />
							}

							{user?.permisos['notas-venta.exportar'] &&
								<Button isDownload text='Exportar' icon='Download' color='info' className='p-50' url={CONSTANT.fetchNvPdf(id)} />
							}

							{user.permisos[`notas-venta.editar`] &&
								<Button text='Editar' icon='Settings' color='warning' className='p-50' isLink url={`/erp/notas-ventas/update/${id}`} />
							}

							<div className='d-flex gap-1'>
								<Button icon={`${displayAllAccordion ? 'Minimize2' : 'Maximize2'}`} className='p-50' onClick={() => setDisplayAllAccodion(!displayAllAccordion)} />
								{/* <DropdownMenu
								icon='MoreHorizontal'
							/> */}
							</div>
						</div>
					}
				</div>

			</div>

			{data
				? <div className='row'>

					{/* COLUMNA IZQUIERDA */}
					<div className='col-12 col-md-6'>
						{/* DATOS GENERALES */}
						<div className='col mb-1'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
								isOpen={displayAllAccordion}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Fecha creación'} value={<b>{data?.detalle_nv?.ORDER_DATE?.split('T')?.[0] ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Forma de pago'} value={<b>{data?.detalle_nv?.TERM_CODE ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Facturación'} value={<b>{data?.detalle_nv?.UD_TIPO_FACTURACION ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Pedido'}
											value={<b>{data?.detalle_nv?.EXT_NUM
												? <a href={`/pedidos/detalle/${data?.detalle_nv?.EXT_NUM}`}>{data?.detalle_nv?.EXT_NUM}</a>
												: 'N/A'}</b>}
										/>
										<AccordionDetailData dataKey={'Estatus'} value={<b>{data?.detalle_nv?.STATUS_IND ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Estatus venta'} value={<b>{data?.detalle_nv?.UD_ESTATUS_VENTA ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Estatus logistica'} value={<b>{data?.detalle_nv?.UD_ESTATUS_LOGISTICA ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Estatus bodega'} value={<b>{data?.detalle_nv?.UD_ESTATUS_BODEGA ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Orden a proveedor'} value={<b>{data?.ordenes_compra.length ? 'Si' : 'No'}</b>} />
										<AccordionDetailData dataKey={'Total'} value={<b>{data?.detalle_nv?.TOTAL_AMT?.length ? '$ ' + data?.detalle_nv?.TOTAL_AMT : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Neto'} value={<b>{data?.detalle_nv?.GOODS_AMT?.length ? '$ ' + data?.detalle_nv?.GOODS_AMT : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Descuento'} value={<b>{data?.detalle_nv?.DISC_AMT?.length ? '$ ' + data?.detalle_nv?.DISC_AMT : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Monto pagado'} value={<b>{data?.detalle_nv?.SUM_MONTO_PAGADO?.length ? '$ ' + data?.detalle_nv?.SUM_MONTO_PAGADO : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Vendedor'} value={<b>{data?.detalle_nv?.NOMBRE_VENDEDOR?.length ? data?.detalle_nv?.NOMBRE_VENDEDOR : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Agenda'} value={
											data?.agenda?.agendamientos?.[0]?.agendamiento
												? <div className='tag dark-blue px-50 mx-auto'>
													<b>
														<p className='my-0 text-center'>{data?.agenda?.agendamientos?.[0]?.agendamiento?.fecha?.split('T')[0] ?? '?'}</p>
														<p className='my-0 text-center'>{data?.agenda?.agendamientos?.[0]?.agendamiento?.hora_agenda?.hora_inicio_intervalo ?? '?'} - {data?.agenda?.agendamientos?.[0]?.agendamiento?.hora_agenda?.hora_final_intervalo ?? '?'}</p>
													</b>
												</div>
												: <p className='tag dark-blue px-50 mx-auto'><b>N/A</b></p>
										} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* OBSERVACIONES */}
						<div className='col mb-1'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Descripción</h3>}
								isOpen={displayAllAccordion}
							>
								<p className='m-0 px-1'>{data?.detalle_nv?.COM_TEXT?.length ? data?.detalle_nv?.COM_TEXT : 'Sin observaciones'}</p>
							</Accordion>
						</div>
					</div>

					{/* COLUMNA DERECHA */}
					<div className='col-12 col-md-6'>
						{/* CLIENTE */}
						<div className='col mb-1'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Cliente</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Nombre y apellido'} value={<b>{data?.detalle_nv?.CLIENTE_NOMBRE?.length ? data?.detalle_nv?.CLIENTE_NOMBRE : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Teléfono'} value={<b>{data?.detalle_nv?.CLIENTE_TELEFONO?.length ? data?.detalle_nv?.CLIENTE_TELEFONO : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Email'} value={<b style={{ wordBreak: 'break-all' }}>{data?.detalle_nv?.CLIENTE_EMAIL?.length ? data?.detalle_nv?.CLIENTE_EMAIL : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'RUT'} value={<b>{data?.detalle_nv?.CLIENTE_RUT?.length ? data?.detalle_nv?.CLIENTE_RUT : 'N/A'}</b>} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* PUNTO ENTREGA */}
						<div className='col mb-1'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Punto de entrega</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Despachar a'} value={`${data?.detalle_nv?.SHIP_CODE ?? 'N/A'}`} />
										<AccordionDetailData dataKey={'Comuna/Destino'} value={data?.detalle_nv?.COMUNA_ENTREGA ?? 'N/A'} />
										<AccordionDetailData dataKey={'Nombre'} value={data?.detalle_nv?.SHIP_NAME_TEXT ?? 'N/A'} />
										<AccordionDetailData dataKey={'Dirección'} value={data?.detalle_nv?.SHIP_ADDR_TEXT ?? 'N/A'} />
										<AccordionDetailData dataKey={'Método Entrega'} value={data?.detalle_nv?.MODO_DESPACHO ?? 'N/A'} />
										<AccordionDetailData dataKey={'Tipo destino'} value={data?.detalle_nv?.UD_TIPO_DESTI ?? 'N/A'} />
										<AccordionDetailData dataKey={'Planificación'} value={data?.detalle_nv?.SHIP_DATE?.split('T')?.[0] ?? 'N/A'} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* DATOS FACTURA */}
						{data?.detalle_nv?.UD_TIPO_FACTURACION === 'Factura' &&
							<div className='col mb-1'>
								<Accordion
									isOpen={displayAllAccordion}
									title={<h3 className='m-0 w-100 text-center text-white'>Datos factura</h3>}
								>
									<div className='col px-2 align-items-center'>
										<div className='row'>
											<AccordionDetailData dataKey={'Facturar a'} value={`${data?.detalle_nv?.BILL_CODE ?? 'N/A'}`} />
											<AccordionDetailData dataKey={'Comuna'} value={data?.detalle_nv?.COMUNA_FACTURA ?? 'N/A'} />
											<AccordionDetailData dataKey={'Nombre'} value={data?.detalle_nv?.BILL_NAME_TEXT ?? 'N/A'} />
											<AccordionDetailData dataKey={'Dirección'} value={data?.detalle_nv?.BILL_ADDR_TEXT ?? 'N/A'} last />
										</div>
									</div>
								</Accordion>
							</div>
						}
					</div>

					{/* PRODUCTOS */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Productos'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead>
								<tr>
									<th>Línea Item</th>
									<th>Descripción</th>
									<th>SKU</th>
									<th>Bodega</th>
									<th>Qty</th>
									<th>Qty reservada</th>
									<th>Qty pdte.</th>
									<th>P. unit. neto</th>
									<th>P. subtotal neto</th>
								</tr>
							</thead>
							<tbody>
								{data?.lineas_nv?.length
									? data?.lineas_nv?.map(({
										DESC_TEXT, PART_CODE, WARE_CODE,
										UOM_QTY, SCHED_QTY, BACK_QTY,
										UNIT_PRICE_AMT, EXT_PRICE_AMT, LINE_NUM }, i) => (
										<tr key={`lineas-pedido-erp-${DESC_TEXT ?? 'producto'}-${i ?? PART_CODE}`}>

											<td><b>{LINE_NUM ?? 'N/A'}</b></td>

											<td><a href='/producto/detalle'>{DESC_TEXT.toUpperCase() ?? 'N/A'}</a></td>

											<td><b>{PART_CODE ?? 'N/A'}</b></td>

											<td><b>{WARE_CODE ?? 'N/A'}</b></td>

											<td><b>{UOM_QTY ?? 'N/A'}</b></td>

											<td><b>{SCHED_QTY ?? 'N/A'}</b></td>

											<td><b>{BACK_QTY ?? 'N/A'}</b></td>

											<td><b>{UNIT_PRICE_AMT?.length ? '$ ' + UNIT_PRICE_AMT : 'N/A'}</b></td>

											<td><b>{EXT_PRICE_AMT?.length ? '$ ' + EXT_PRICE_AMT : 'N/A'}</b></td>

										</tr>
									))
									: <tr><td colSpan={8}><p className='tag dark-blue px-50 m-auto'><b>Error de conexión</b></p></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* TRACKING */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Tracking'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead>
								<tr>
									<th>Imagen</th>
									<th>Ruta</th>
									<th className='col-1'>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{tracking?.length
									? tracking.map((imagen, i) => (
										<tr key={`detalle-tracking-${imagen ?? 'producto'}-${i}`}>
											<td>
												<div className='d-flex w-100 justify-content-center'>
													<img
														key={`clave-atributo-imagen-${i}-${imagen ?? 'unk'}`}
														className='height-listado p-50 border rounded-2'
														src={BASE_URL + 'storage/' + imagen}
														alt="img-tracking"
														onClick={() => handleModalData(BASE_URL + 'storage/' + imagen, 'img-zoom')}
													/>
												</div>
											</td>

											<td><b>{imagen}</b></td>

											<td>
												<DropdownWrapper icon='MoreHorizontal'>
													<DropdownMenuLink text='Descargar' icon='Download' onClick={() => handleFetch('storage/' + imagen, 'download')} download={'imagen1'} />
												</DropdownWrapper>
											</td>
										</tr>
									))

									: <tr><td colSpan={3}><p className='tag dark-blue px-50 m-auto'><b>Sin resultados</b></p></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* EVIDENCIA */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Evidencia'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead>
								<tr>
									<th>Imagen</th>
									<th>Ruta</th>
									<th className='col-1'>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{evidencia?.length
									? evidencia.map((imagen, i) => (
										<tr key={`detalle-evidencia-${imagen ?? 'producto'}-${i}`}>
											<td>
												<div className='d-flex w-100 justify-content-center'>
													<img
														key={`clave-atributo-imagen-${i}-${imagen ?? 'unk'}`}
														className='height-listado p-50 border rounded-2'
														src={BASE_URL + 'storage/' + imagen}
														alt="img"
														onClick={() => handleModalData(BASE_URL + 'storage/' + imagen, 'img-zoom')}
													/>
												</div>
											</td>

											<td><b>{imagen}</b></td>

											<td>
												<DropdownWrapper icon='MoreHorizontal'>
													<DropdownMenuLink text='Descargar' icon='Download' onClick={() => handleFetch('storage/' + imagen, 'download')} download={'imagen1'} />
												</DropdownWrapper>
											</td>
										</tr>
									))

									: <tr><td colSpan={3}><p className='tag dark-blue px-50 m-auto'><b>Sin resultados</b></p></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* FACTURA */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Facturas'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>DXC</th>
									<th>Cliente</th>
									<th>NV</th>
									<th>Factura SII</th>
									<th>Guía</th>
									<th>Montos</th>
									<th>Pagado</th>
									<th>Acc.</th>
								</tr>
							</thead>
							<tbody>
								{data?.facturas_nv?.length

									? data?.facturas_nv?.map(({
										INV_NUM, INV_DATE, ENTRY_CODE, CUST_CODE, ORD_NUM, GUIA_NUM, GOODS_AMT, TAX_AMT, TOTAL_AMT, PAID_AMT, COM_TEXT, NAME_TEXT, EXT_NUM
									}, i) => (
										<React.Fragment key={`factura-${ORD_NUM}-${i ?? GUIA_NUM}`}>
											<tr style={{ backgroundColor: i % 2 > 0 ? '#ededeb' : '#fff' }}>
												<td rowSpan={2}>
													<div className='d-flex flex-column'>
														<b>#{INV_NUM ?? 'N/A'}</b>
														<span>Digitador: <b>{ENTRY_CODE ?? 'N/A'}</b></span>
														<span>{INV_DATE?.split('T')[0] ?? '?'}</span>
													</div>
												</td>

												<td>
													<div className='d-flex flex-column'>
														<b>{NAME_TEXT ?? 'N/A'}</b>
														<span>RUT: <b>{CUST_CODE ?? 'N/A'}</b></span>
													</div>
												</td>


												<td><b>{ORD_NUM ?? 'N/A'}</b></td>

												<td><b>{EXT_NUM ?? 'N/A'}</b></td>

												<td><b>{GUIA_NUM ?? 'N/A'}</b></td>

												<td style={{ backgroundColor: i % 2 > 0 ? '#ededeb' : '#fff' }}>
													<div className='d-flex flex-column'>
														<b className='fs-3'>{TOTAL_AMT?.length ? '$ ' + TOTAL_AMT : 'N/A'}</b>
														<span>Subtotal: <b>{GOODS_AMT?.length ? '$ ' + GOODS_AMT : 'N/A'}</b></span>
														<span>Impuesto: <b>{TAX_AMT?.length ? '$ ' + TAX_AMT : 'N/A'}</b></span>
													</div>
												</td>

												<td><b>{PAID_AMT?.length ? '$ ' + PAID_AMT : 'N/A'}</b></td>

												<td>
													<Button text='' className='p-25' color='success' icon='Download' disabled={!EXT_NUM} onClick={() => handleFetch({ id: INV_NUM }, 'descargar-factura')} />
												</td>
											</tr>

											<tr>
												<td colSpan={7}>
													<div className='d-flex flex-column'>
														<b>Comentario:</b>
														<span>{COM_TEXT?.length ? COM_TEXT : 'Sin comentarios'}</span>
													</div>
												</td>
											</tr>

										</React.Fragment>

									))

									: <tr><td colSpan={8}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* ORDENES COMPRA */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Órdenes de compra'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Orden compra</th>
									<th>SKU</th>
									<th>Bodega</th>
									<th>Qty solicitada</th>
									<th>Precio unit. neto</th>
									<th>Precio total</th>
								</tr>
							</thead>
							<tbody>
								{data?.ordenes_compra?.length

									? data?.ordenes_compra?.map(({
										NUM_OC, FECHA_OC, PART_CODE, DESC_TEXT, BODEGA_OC, ORDER_QTY, UNIT_PRICE_AMT, LINE_TOTAL_AMT
									}, i) => (
										<React.Fragment key={`ordenes-compra-${NUM_OC}-${i ?? FECHA_OC}`}>
											<tr>
												<td rowSpan={2} style={{ borderBottom: '1px solid #444' }}>
													<div className='d-flex flex-column'>
														<b>#{NUM_OC ?? 'N/A'}</b>
														<span>{FECHA_OC?.split('T')[0] ?? '?'}</span>
													</div>
												</td>

												<td><b>{PART_CODE ?? 'N/A'}</b></td>

												<td><b>{BODEGA_OC ?? 'N/A'}</b></td>

												<td><b>{ORDER_QTY ?? 'N/A'}</b></td>

												<td><b>{UNIT_PRICE_AMT ?? 'N/A'}</b></td>

												<td><b>{LINE_TOTAL_AMT ?? 'N/A'}</b></td>

											</tr>

											<tr style={{ borderBottom: '#444' }}>
												<td colSpan={6}>
													<div className='d-flex flex-column'>
														<b>Descripción:</b>
														<span>{DESC_TEXT?.length ? DESC_TEXT : 'Sin descripción'}</span>
													</div>
												</td>
											</tr>

										</React.Fragment>

									))

									: <tr><td colSpan={7}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* PAGOS */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Pagos'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Pago</th>
									<th>Digitador</th>
									<th>Tipo de pago</th>
									<th>Monto pago</th>
									<th>Monto aplicado</th>
									<th>Estatus</th>
								</tr>
							</thead>
							<tbody>
								{data?.pagos_nv?.length

									? data?.pagos_nv?.map(({
										CASH_NUM, ENTRY_DATE, ENTRY_CODE, CASH_TYPE_IND, CASH_AMT, APPLIED_AMT, POSTED_FLAG
									}, i) => (
										<tr key={`pagos-${CASH_NUM}-${i ?? ENTRY_DATE}`}>
											<td>
												<div className='d-flex flex-column'>
													<b>#{CASH_NUM ?? 'N/A'}</b>
													<span>{ENTRY_DATE?.split('T')[0] ?? '?'}</span>
												</div>
											</td>

											<td><b>{ENTRY_CODE ?? 'N/A'}</b></td>

											<td><b>{CONSTANT.tiposPagos[CASH_TYPE_IND ?? 'default'] ?? 'N/A'}</b></td>

											<td><b>{CASH_AMT?.length ? '$ ' + CASH_AMT : 'N/A'}</b></td>

											<td><b>{APPLIED_AMT?.length ? '$ ' + APPLIED_AMT : 'N/A'}</b></td>

											<td><b>{POSTED_FLAG ?? 'N/A'}</b></td>

										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* GUIAS DESPACHO */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Guías despacho'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Guía</th>
									<th>Tipo guía</th>
									<th>Digitador</th>
									<th>Monto neto</th>
									<th>Monto total</th>
									<th>Dirección despacho</th>
								</tr>
							</thead>
							<tbody>
								{data?.guias_despacho_nv?.length

									? data?.guias_despacho_nv?.map(({
										GUIA_NUM, ENTRY_DATE, COD_MOV, ENTRY_CODE, GOODS_AMT, TOTAL_AMT, SHIP_TEXT, ITEMS_GUIAS
									}, i) => (
										<React.Fragment key={`pagos-${GUIA_NUM}-${i ?? ENTRY_DATE}`}>
											<tr>
												<td rowSpan={2}>
													<div className='d-flex flex-column'>
														<b>#{GUIA_NUM ?? 'N/A'}</b>
														<span>{ENTRY_DATE?.split('T')[0] ?? '?'}</span>
													</div>
												</td>

												<td><b>{COD_MOV ?? 'N/A'}</b></td>

												<td><b>{ENTRY_CODE ?? 'N/A'}</b></td>

												<td><b>{GOODS_AMT?.length ? '$ ' + GOODS_AMT : 'N/A'}</b></td>

												<td><b>{TOTAL_AMT?.length ? '$ ' + TOTAL_AMT : 'N/A'}</b></td>

												<td><b>{SHIP_TEXT ?? 'N/A'}</b></td>
											</tr>

											<tr className={`odd multi-data}`}>
												<td colSpan={5} className='p-0 pb-50'>
													<AccordionTable title={`Líneas (${ITEMS_GUIAS?.length ?? '?'})`}>
														<Table style={{ maxHeight: '900px' }} pb={false}>
															<thead>
																<tr>
																	<th>Línea</th>
																	<th>SKU</th>
																	<th>Producto</th>
																	<th>Bodega Inicio</th>
																	<th>Bodega Final</th>
																	<th>P. Línea</th>
																	<th>Qty</th>
																</tr>
															</thead>
															<tbody>
																{ITEMS_GUIAS?.length
																	? ITEMS_GUIAS.map(({ linea, sku, producto, bodega_inicio, bodega_final, precio_linea, cantidad }, i) => (
																		<tr key={`lineas-pedido-erp-${linea ?? 'producto'}-${i ?? sku}`}>
																			<td>{linea ?? 'N/A'}</td>
																			<td className='text-center'>{sku ?? 'N/A'}</td>
																			<td>
																				<b>{producto.toUpperCase() ?? 'N/A'}</b>
																			</td>
																			<td className='text-center'>{bodega_inicio ?? 'N/A'}</td>
																			<td className='text-center'>{bodega_final ?? 'N/A'}</td>
																			<td className='text-center'>{precio_linea ?? 'N/A'}</td>
																			<td className='text-center text-nowrap'>{cantidad ?? 'N/A'}</td>
																		</tr>
																	))
																	: <tr><td colSpan={7}><p className='tag dark-blue px-50 m-auto'><b>Error de conexión</b></p></td></tr>
																}
															</tbody>
														</Table>
													</AccordionTable>
												</td>
											</tr>
										</React.Fragment>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* NOTAS CREDITO */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Notas de crédito'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Notas crédito</th>
									<th>Digitador</th>
									<th>Cliente</th>
									<th>Monto neto</th>
									<th>Monto total</th>
								</tr>
							</thead>
							<tbody>
								{data?.notas_creditos_nv?.length

									? data?.notas_creditos_nv?.map(({
										CRED_NUM, ENTRY_DATE, ENTRY_CODE, BILL_CODE, BILL_NAME_TEXT, GOODS_AMT, TOTAL_AMT
									}, i) => (
										<tr key={`pagos-${CRED_NUM}-${i ?? ENTRY_DATE}`}>
											<td>
												<div className='d-flex flex-column'>
													<b>#{CRED_NUM ?? 'N/A'}</b>
													<span>{ENTRY_DATE?.split('T')[0] ?? '?'}</span>
												</div>
											</td>

											<td><b>{ENTRY_CODE ?? 'N/A'}</b></td>

											<td>
												<div className='d-flex flex-column'>
													<b>{BILL_NAME_TEXT ?? 'N/A'}</b>
													<span>RUT: <b>{BILL_CODE ?? 'N/A'}</b></span>
												</div>
											</td>

											<td><b>{GOODS_AMT?.length ? '$ ' + GOODS_AMT : 'N/A'}</b></td>

											<td><b>{TOTAL_AMT?.length ? '$ ' + TOTAL_AMT : 'N/A'}</b></td>
										</tr>
									))

									: <tr><td colSpan={5}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					<TablaDetalle historicoData={historico} fnHandleModal={handleModalHistorico} />

				</div >

				: <CustomLoader />
			}
		</>
	)
}

export default Detalle