
// Components
import { ReportePedidosCotizacionesFiltros } from "components/AccordionFilter/ReportePedidosCotizaciones";
import { ReportePedidosCotizacionesTable } from "components/Tables/ReportePedidosCotizacionesTable";

const CONSTANT = {
	title: 'Reporte | Pedidos - Cotizaciones',
	path: 'reporte-pedidos-cotizaciones',
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
			</h3>

			<ReportePedidosCotizacionesFiltros />

			<ReportePedidosCotizacionesTable />
		</div>
	);
}
