import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../views/EmpresasPagos/Listado"

export const EmpresasPagosRouter = () => {
   // const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
