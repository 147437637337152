
// Components
import Button from "components/Button";
import { EmpresasClasificacionesFiltros } from "components/AccordionFilter/EmpresasClasificaciones";
import { EmpresasClasificacionesTable } from "components/Tables/EmpresasClasificacionesTable";

const CONSTANT = {
	title: 'Empresas | Clasificaciones',
	path: 'empresas-clasificaciones',
	create_btn: 'Crear clasificación de empresa'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<Button text={CONSTANT.create_btn} isLink url={`/${CONSTANT.path}/create`} icon="Plus" />
			</h3>

			<EmpresasClasificacionesFiltros />

			<EmpresasClasificacionesTable />
		</div>
	);
}
