import { useCallback, useEffect, useState } from 'react'


// Redux
import { fireToaster } from 'redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Button from 'components/Button'
import CustomLoader from 'components/CustomLoader'
import { ModalHistorico } from 'components/Historico/ModalHistorico'
import Tabs from 'components/Tabs'
import ModalAprobarEmpresa from 'components/Modal/Empresas/ModalAprobarEmpresa'
import Informacion from './DetallesTabs/Informacion'


// Router Dom
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'


// Services
import { getDetail } from 'services/empresas/getDetail.service'


// Helpers
// import { historicoData } from 'helpers/historicoData'


import CuentaCorriente from './DetallesTabs/CuentaCorriente'
import Sucursales from './DetallesTabs/Sucursales'
import EmpresasAsociadas from './DetallesTabs/EmpresasAsociadas'
import GraficosCalculos from './DetallesTabs/GraficosCalculos'
import { responseToasterContent } from 'helpers/toasterContent'


const CONSTANT = {
   title: 'Empresas',
   redirectListado: '/empresas',
   modelo: 'App\\Models\\NeuEmpresa',
   tabs: [
      { name: 'Informacion', url: '?tab=informacion' },
      { name: 'Cuenta corriente', url: '?tab=cuenta-corriente' },
      { name: 'Sucursales', url: '?tab=sucursales' },
      { name: 'Empresas asociadas', url: '?tab=empresas-asociadas' },
      { name: 'Gráficos y cálculos', url: '?tab=graficos-calculos' },
   ],
   redirectUpdate: (id) => `/empresas/update/${id}`,
}


const TabSelector = ({ tabParam, data, cb }) => {

   switch (tabParam) {
      case 'informacion':
         return <Informacion data={data} cb={cb} />

      case 'cuenta-corriente':

         return <CuentaCorriente data={data} />

      case 'sucursales':

         return <Sucursales data={data} cb={cb} />

      case 'empresas-asociadas':

         return <EmpresasAsociadas data={data} />

      case 'graficos-calculos':

         return <GraficosCalculos data={data} cb={cb} />

      default:
         return <Informacion data={data} cb={cb} />
   }
}


export const Detalle = () => {
   const [data, setData] = useState(null)
   const { id } = useParams()
   const dispatch = useDispatch()
   const redirect = useNavigate()
   // const [historico, setHistorico] = useState(null)
   const [modal, setModal] = useState({ id: null, display: false, data: null })
   const [searchParams] = useSearchParams()
   const [params, setParams] = useState({})

   const [modalApproval, setModalApproval] = useState(false)

   const fetchData = useCallback(() => {
      getDetail(id)
         .then(res => {
            if (res.status >= 400) throw res

            setData(res.data)
         })
         .catch((err) => {
            console.error(err)
            dispatch(fireToaster(responseToasterContent(err)))

            redirect(CONSTANT.redirectListado)
         })
   }, [id, dispatch, redirect])


   // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
   useEffect(() => {
      fetchData()

      return () => {
         setData(null)
      }
   }, [fetchData])

   useEffect(() => {
      let currentParams = {}

      for (const [k, v] of searchParams) {
         currentParams = { ...currentParams, [k]: v }
      }

      setParams(currentParams)
   }, [searchParams])

   // FUNCION MANEJADORA DE LA DATA DEL MODAL
   const handleModal = (data) => {
      const { id, anterior, nuevo } = data

      setModal({
         display: true,
         id,
         data: {
            anterior,
            nuevo
         }
      })
   }

   const handleApproval = async () => {
      setModalApproval(false)
   }

   return (
      <div className='row'>
         <ModalAprobarEmpresa data={data} isOpen={modalApproval} handleClose={handleApproval} />

         <ModalHistorico modal={modal} fnHandleModal={handleModal} />

         <div className='col-12'>
            <div className='col mb-50'>
               <div className='d-flex gap-1'>
                  <div className='col'>
                     <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
                  </div>
                  <Button text='Editar' icon='Settings' color='warning' isLink url={CONSTANT.redirectUpdate(id)} />

                  <Button text='Aprobar' icon='ThumbsUp' color='success' onClick={() => setModalApproval(true)} />
               </div>

               <div className='mt-1'>
                  <Tabs tabs={CONSTANT.tabs} />
               </div>
            </div>

            <div className='row'>
               {data
                  ? <>
                     <TabSelector tabParam={params?.tab} data={data} cb={fetchData} />

                     {/* <TablaDetalle historicoData={historico} fnHandleModal={handleModal} /> */}
                  </>

                  : <CustomLoader />
               }
            </div>
         </div>
      </div>
   )
}
