import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterEstatusPedido, resultadosEstatusPedido } from '../../redux/actions/estatusPedido';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
	pathUrl: '/generales/estatus-pedido',
	permissionsPath: 'estatus-pedido',
	queryUrlGetOne: getOne.estatus_pedido,
	title: 'Estatus de pedido',
	reduxClearFilters: clearFilterEstatusPedido,
	reduxSetResults: resultadosEstatusPedido,
}


const EstatusPedidoTable = () => {
	const { resultados: response, filtros } = useSelector(state => state.estatusPedido)
	const { showing_results } = useSelector(state => state.listedResults)
	const { busqueda } = filtros
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.auth)


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		dispatch(CONSTANT.reduxSetResults(null))
		axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
			.catch(err => console.error(err))
	}


	// FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
	const handleEstatus = async (objData, url, target) => {
		let newEstatus = objData[target] ? 0 : 1

		let data = { [target]: newEstatus, _method: 'PUT' }

		await axios(CONSTANT.queryUrlGetOne(objData.id), {
			method: 'POST',
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
				'Content-Type': 'application/json'
			},
			data
		})
			.then(res => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: ${target} actualizado con éxito`,
					icon: 'success'
				}

				dispatch(fireToaster(toasterContent))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})

		dispatch(CONSTANT.reduxSetResults(null))

		axios(url, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
			}
		})
			.then(res => {
				dispatch(CONSTANT.reduxSetResults(res.data))
			})
			.catch(err => console.error(err))
	}


	// FUNCIÓN MANEJADORA PARA BORRAR UN REGISTRO
	const handleDelete = async (id, url) => {
		await axios(CONSTANT.queryUrlGetOne(id), {
			method: 'DELETE',
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
				'Content-Type': 'application/json',
				_method: 'DELETE'
			}
		})
			.then(res => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: registro eliminado con éxito`,
					icon: 'info'
				}

				dispatch(fireToaster(toasterContent))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
         Operación fallida
         (${err.response.status} - ${err.response.statusText})
       `,
					html: `
         <b>Detalle: </b>
         ${detalles.map(error => `<br /><i>-${error}</i>`)}
       `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})

		dispatch(CONSTANT.reduxSetResults(null))

		await axios(url, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
			}
		})
			.then(res => {
				dispatch(CONSTANT.reduxSetResults(res.data))
			})
			.catch(err => console.error(err))
	}


	return (
		<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
			{!response && <CustomLoader blocking={'partial'} />}

			<Table response={response} handlePagination={handleUrl}>
				<thead className="table-light position-sticky top-0 w-100 z-100 shadow text-center">
					<tr role="row">
						<th>ID</th>
						<th>Nombre</th>
						<th># Paso</th>
						<th>Creación</th>
						<th>Estatus</th>
						<th>Usar Tienda</th>
						<th>Usar B2B</th>
						<th className="w-max">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{response?.data.length
						? response.data?.map((data) => {
							return (
								<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
									<td className='col-1'>{data?.id}</td>
									<td>{data?.nombre ?? "N/A"}</td>
									<td className='col-1'>{data?.paso ?? "N/A"}</td>
									<td className='col-1'>{data?.created_at?.split("T")[0] || 'N/A'}</td>

									<td className='col-1'>
										<Switch
											value={data.estatus ? 1 : 0}
											labelText={""}
											handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')}
										/>
									</td>

									<td className='col-1'>
										<Switch
											value={data.usar_tienda ? 1 : 0}
											labelText={""}
											handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'usar_tienda')}
										/>
									</td>

									<td className='col-1'>
										<Switch
											value={data.usar_b2b ? 1 : 0}
											labelText={""}
											handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'usar_b2b')}
										/>
									</td>

									<td className='col-1'>
										<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
											{user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
												<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
											}

											{user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
												<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleDelete(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'estatus')} />
											}
										</DropdownWrapper>
									</td>
								</tr>
							)
						})
						: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
					}
				</tbody>
			</Table>
		</AccordionTable >
	)
}

export default EstatusPedidoTable