import { useDispatch } from "react-redux"
import { useState } from "react"

// Redux
import { fireToaster } from "redux/actions/toaster"


// Components
import Modal from "components/Modal"
import Button from "components/Button"
import FileUploaderB from "components/FileUploader_B"

// Services
import { createEmpresaAreasImagenes } from "services/empresas/createEmpresaAreaImagenes.service"

// Helpers
import { responseToasterContent } from "helpers/toasterContent"
import { BASE_URL } from "endpoints"
import { deleteEmpresaAreasImagenes } from "services/empresas/deleteEmpresaAreaImagen.service"


const ModalAreaImagenes = ({ data, isOpen, handleClose, endCb }) => {
  const dispatch = useDispatch()
  const [imagenes, setImagenes] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!imagenes.length) return dispatch(fireToaster({ title: 'Debe enviar por lo menos una imagen', icon: 'info' }))

    await createEmpresaAreasImagenes({ area_id: data?.id, imagenes })
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        handleClose()
        endCb()
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
        console.error(err);
      })
  }

  const handleDeleteImage = async (id) => {
    window.confirm('¿Está seguro que desea eliminar esta imagen?') &&
      await deleteEmpresaAreasImagenes(id)
        .then(res => {
          handleClose()
          dispatch(fireToaster(responseToasterContent(res)))
          endCb()
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
          console.error(err);
        })
  }

  return (
    <Modal display={isOpen} handleValue={handleClose} innerModalStyle={{ width: '70%' }}>
      <h3>Imagenes | Área #{data?.id ?? '-'}</h3>

      <div className="d-flex flex-wrap gap-2">
        {data?.imagenes?.length
          ? data?.imagenes.map(i => (
            <figure className="position-relative" key={`imagen-area-${i.id}-${i.nombre}`}>
              <img
                style={{ height: '140px', width: 'auto' }}
                src={`${BASE_URL}/storage/${i.ruta}`}
                alt={`Imagen del área '${data?.id}', datos: ${i.nombre}`}
              />

              <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                <Button
                  icon={'X'}
                  color="danger"
                  className='p-25'
                  onClick={() => handleDeleteImage(i?.id)}
                />
              </div>
            </figure>
          ))

          : <i>Sin imágenes para mostrar...</i>
        }
      </div>

      <hr />

      <form onSubmit={handleSubmit}>
        <span>Agregar</span>
        <FileUploaderB handleValue={setImagenes} value={imagenes} labelText='Imágenes' />

        <div className="row">
          <div className="col-12 d-flex justify-content-around mt-2">
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} />

            <Button text={data?.id ? `Guardar` : `Crear`} onClick={handleSubmit} color={'success'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalAreaImagenes