import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"

// Redux
import { fireToaster } from "../../../redux/actions/toaster"


// Components
import Modal from "../../Modal"
import FormInput from "../../FormInput"
import Button from "../../Button"


// Services
import { createEmpresaContactos } from "services/empresas/createEmpresaContacto.service"
import { updateEmpresasContactos } from "services/empresas/updateEmpresasContacto.service"
import { responseToasterContent } from "helpers/toasterContent"


const CONSTANT = {
  form: {
    sucursal_id: '',
    nombre: '',
    cargo: '',
    correo: '',
    telefono: ''
  },
}

const ModalCrearContacto = ({ data, isOpen, handleClose, endCb }) => {
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()


  useEffect(() => {
    setForm(prev => ({
      ...prev,
      ...data
    }))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [isOpen, data])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (['telefono'].includes(name) && isNaN(Number(value))) return

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!form.nombre) return dispatch(fireToaster({ title: 'El nombre es necesario', icon: 'info' }))

    data?.id
      ? await updateEmpresasContactos(data?.id, { ...form })
        .then(res => {
          handleClose()
          dispatch(fireToaster(responseToasterContent(res)))
          endCb()
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
          console.error(err);
        })
      : await createEmpresaContactos({ ...form })
        .then(res => {
          handleClose()
          dispatch(fireToaster(responseToasterContent(res)))
          endCb()
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
          console.error(err);
        })
  }


  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3>Crear contacto | Sucursal #{data?.sucursal_id}</h3>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormInput
            labelText='* Nombre'
            placeholder='Ingrese un nombre'
            size='col-12'
            name={`nombre`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.nombre ?? ''}
          />

          <FormInput
            labelText='Cargo'
            placeholder='Ingrese un cargo'
            size='col-12'
            name={`cargo`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.cargo ?? ''}
          />

          <FormInput
            labelText='Correo'
            placeholder='Ingrese un porcentaje'
            size='col-12'
            name={`correo`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.correo ?? ''}
          />

          <FormInput
            labelText='Teléfono'
            type={'number'}
            name={`telefono`}
            placeholder='Ingrese una número de teléfono'
            size='col-12'
            handleValue={handleInputChange}
            sizeDesk='col-md-6'
            value={form.telefono ?? ''}
          />

          <div className="col-12 d-flex justify-content-around mt-4">
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} />
            <Button text={data?.id ? `Guardar` : `Crear`} onClick={handleSubmit} color={'success'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalCrearContacto