import {
   FILTER_CLEAR,
   RESULTADOS_EMPRESAS_PAGOS,
} from "../redux-types";

const initialState = {
   filtros: {
   },
   resultados: null
}


export const empresasPagosReducer = (state = initialState, action) => {
   switch (action.type) {
      case RESULTADOS_EMPRESAS_PAGOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return { ...state, filtros: { ...initialState.filtros } };

      default:
         return state;
   }
};