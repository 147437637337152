
// Components
import PagosPedidosFiltros from "components/AccordionFilter/PagosPedidos";
// import DropdownMenuLink from "../../components/Dropdown/Link";
// import DropdownWrapper from "../../components/Dropdown/Wrapper";
import { PagosPedidosTable } from "components/Tables/PagosPedidos/PagosPedidosTable";

const CONSTANT = {
	title: 'Pagos a pedidos',
	path: 'pagos-pedidos',
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
			</h3>

			<PagosPedidosFiltros />

			<PagosPedidosTable />
		</div>
	);
}

export default Listado;