import axios from "axios"
import { getAll } from "../../endpoints/getAll"

const URL = getAll.proveedores_transportes

export const getDetailProveedoresTransportes = async (id) => {
  try {
    return await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}