import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"

// Redux
import { fireToaster } from "../../../redux/actions/toaster"


// Components
import Modal from "../../Modal"
import FormInput from "../../FormInput"
import Button from "../../Button"


// Services
import { responseToasterContent } from "helpers/toasterContent"
import FileUploaderB from "components/FileUploader_B"
import { createEmpresaAreas } from "services/empresas/createEmpresaArea.service"
import { updateEmpresasAreas } from "services/empresas/updateEmpresasArea.service"


const CONSTANT = {
  form: {
    nombre: '',
    descripcion: '',
  },
}

const ModalCrearArea = ({ data, isOpen, handleClose, endCb }) => {
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()
  const [imagenes, setImagenes] = useState([])


  useEffect(() => {
    setForm(prev => ({
      ...prev,
      ...data
    }))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [isOpen, data])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!form.nombre) return dispatch(fireToaster({ title: 'El nombre es necesario', icon: 'info' }))
    if (!form.descripcion) return dispatch(fireToaster({ title: 'El descripción es necesario', icon: 'info' }))

    data?.id
      ? await updateEmpresasAreas(data?.id, { ...form })
        .then(res => {
          handleClose()
          dispatch(fireToaster(responseToasterContent(res)))
          endCb()
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
          console.error(err);
        })
      : await createEmpresaAreas({ ...form, imagenes })
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          handleClose()
          endCb()
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
          console.error(err);
        })


  }


  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3>Crear área | Sucursal #{data?.sucursal_id}</h3>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormInput
            labelText='* Nombre'
            placeholder='Ingrese un nombre'
            size='col-12'
            name={`nombre`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.nombre ?? ''}
          />

          <FormInput
            labelText='* Descripción'
            placeholder='Ingrese un descripción'
            size='col-12'
            name={`descripcion`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.descripcion ?? ''}
          />

          <FileUploaderB handleValue={setImagenes} value={imagenes} labelText='Imágenes' />

          <div className="col-12 d-flex justify-content-around mt-4">
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} />
            <Button text={data?.id ? `Guardar` : `Crear`} onClick={handleSubmit} color={'success'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalCrearArea