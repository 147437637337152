import { useDispatch } from 'react-redux';



// Actions
import { toggleSidebar } from '../../redux/actions/ui';



// Components
import Icon from '../Icon';
import UserNavBarDropdown from '../UserNavBarDropdown';
import { useEffect, useState } from 'react';
import { getAllPagosPendientes } from 'services/empresas/getAllPendingPayments.service';
import { Link } from 'react-router-dom';
import { resultadosEmpresasPagos } from 'redux/actions/empresasPagos';

const intervalSeconds = 30

const NavBar = () => {
  const [pendingPayments, setPendingPayments] = useState(0)
  const dispatch = useDispatch();

  const fetchPendingPayments = () => {
    getAllPagosPendientes()
      .then((res) => {
        setPendingPayments(res.data.length < 100 ? `${res.data.length}` : '+99')
        dispatch(resultadosEmpresasPagos(res))
      })
      .catch(err => {
        console.error(err);
      })
  }

  useEffect(() => {
    let pendingPaymentsInterval = setInterval(() => fetchPendingPayments(), intervalSeconds * 1000)

    return () => {
      clearInterval(pendingPaymentsInterval)
    }
  }, [])


  const handleClick = (e) => {
    // TODO: abrir sidebar
    e.preventDefault();

    dispatch(toggleSidebar());
  }

  return (
    <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl">
      <div className="navbar-container d-flex content justify-content-end">
        <div className="bookmark-wrapper d-flex align-items-center justify-content-between w-100">

          <ul className="nav navbar-nav d-xl-none">
            <li className="nav-item">
              <a
                className='nav-link menu-toggle'
                href='/#'
                onClick={handleClick}
              >
                <Icon icon='Menu' />
              </a>
            </li>
          </ul>

          <ul className="nav navbar-nav">
            {pendingPayments
              ? <li className='pending-payment'>
                <Link className='pending-payment' to={'/empresas-pagos'}>
                  <b>Pagos pendientes</b>

                  <div className='pending-payment-dot'>
                    <span>{pendingPayments}</span>
                  </div>
                </Link>
              </li>
              : <></>
            }
          </ul>

          {/* <ul className="nav navbar-nav">
                  <li className="nav-item d-none d-lg-block">
                     <a className="nav-link nav-link-style" href="/#">
                     <Icon icon='Moon' />
                     </a>
                  </li>
               </ul> */}

          <ul className="nav navbar-nav align-items-center ms-auto">
            <UserNavBarDropdown />
          </ul>
        </div>

      </div>
    </nav>
  );
}



export default NavBar;