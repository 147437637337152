import { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getAll } from "../../endpoints/getAll";
import { getOne } from "../../endpoints/getOne";
import { idExtractor } from "../../helpers/idExtractor";
import { validateRut } from "../../helpers/validateRut";


const CONSTANT = {
  redirectUrl: '/usuarios',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar usuario',
  title: 'Usuarios',
  queryUrl: getOne.usuarios,
  fetchPaises: getAll.paises + '/buscar',
  fecthVendedores: getAll.erp_vendedores + '/obtener',
  fecthUsuarios: getAll.erp_usuarios,
  initialFormState: {
    pais_id: '',
    rut: '',
    email: '',
    nombre: '',
    clave: '',
    confirmar_clave: '',
    porcentaje_descuento_max: '',
    clave_antigua: '',
    fono: '',
    cod_maxi: '*NULL*-Sin selección',
    usuario_erp: '*NULL*-Sin selección',
    estatus: 1,
    cambiar_clave: 0
  },
  selectFilterInitialState: {
    paises: [],
    usuarios: [],
    vendedores: [],
  }
}


const handleSelectFiltersData = async () => {
  const paises = await axios(CONSTANT.fetchPaises,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const vendedores = await axios(CONSTANT.fecthVendedores,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const usuarios = await axios(CONSTANT.fecthUsuarios,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { paises, vendedores, usuarios }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFetchData({
      ...fetchData,
      [name]: value
    })
  }


  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS Y SETEA fetchData
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const selectedUsuario = selectFilter.usuarios.find(({ codigo }) => codigo === data.usuario_erp)
        const selectedVendedor = selectFilter.vendedores.find(({ codigo_vendedor }) => codigo_vendedor === data.cod_maxi)
        setFetchData({
          ...data,
          pais_id: `${data?.pais?.id ?? '*NULL*'}-${data?.pais?.nombre ?? 'Sin selección'}`,
          usuario_erp: `${selectedUsuario?.codigo ?? '*NULL*'}-${selectedUsuario?.usuario ?? 'Sin selección'}`,
          cod_maxi: `${selectedVendedor?.selectedVendedor ?? '*NULL*'}-${selectedVendedor?.vendedor ?? 'Sin selección'}`,
          clave: '',
          clave_supervisor: ''
        })
      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id, selectFilter.usuarios, selectFilter.vendedores])


  // FUNCIÓN MANEJADORA QUE EDITA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    const { patron_identificacion, locale, extension_telefonica } = selectFilter.paises.find((({ id }) => id === idExtractor(fetchData.pais_id)))

    if (!fetchData?.rut?.length) return dispatch(fireToaster({ title: 'El RUT es requerido', icon: 'info' }))
    if (!validateRut(fetchData.rut, patron_identificacion)) return dispatch(fireToaster({ title: 'El RUT no es válido para el país seleccionado', icon: 'info' }))
    if (!fetchData?.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es requerido', icon: 'info' }))
    if (!fetchData?.email?.length) return dispatch(fireToaster({ title: 'El email es requerido', icon: 'info' }))
    if (!fetchData?.fono?.length) return dispatch(fireToaster({ title: 'La teléfono es requerido', icon: 'info' }))
    if (!validator.isEmail(fetchData?.email)) return dispatch(fireToaster({ title: 'El formato del email no es válido', icon: 'info' }))
    if (!validator.isMobilePhone(`${extension_telefonica}${fetchData.fono}`, [locale], { strictMode: true })) return dispatch(fireToaster({ title: 'El teléfono no es válido para el país seleccionado', icon: 'info' }))

    let requestData = {
      pais_id: idExtractor(fetchData.pais_id),
      rut: fetchData.rut,
      email: fetchData.email,
      nombre: fetchData.nombre,
      fono: fetchData.fono,
      cod_maxi: fetchData.cod_maxi.includes('*NULL*') ? '' : String(idExtractor(fetchData.cod_maxi)),
      usuario_erp: fetchData.usuario_erp.includes('*NULL*') ? '' : fetchData.usuario_erp.split('-')[0],
      estatus: fetchData.estatus ? 1 : 0
    }

    if (fetchData.porcentaje_descuento_max) requestData = { ...requestData, porcentaje_descuento_max: fetchData.porcentaje_descuento_max }

    if (fetchData.clave.length) {
      requestData = {
        clave: fetchData.clave,
      }
    }

    if (fetchData.clave_supervisor.length) {
      requestData = {
        clave_supervisor: fetchData.clave_supervisor,
      }
    }

    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'PUT',
        data: requestData
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.paises.length && fetchData?.id
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={fetchData?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <SelectFilter
                labelText="* País"
                name="pais_id"
                value={fetchData?.pais_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.paises?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="% Desc. max."
                name='porcentaje_descuento_max'
                type='number'
                placeholder='10%'
                handleValue={handleInputChange}
                value={fetchData?.porcentaje_descuento_max}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Nombre"
                name='nombre'
                placeholder='Nombre...'
                handleValue={handleInputChange}
                value={fetchData?.nombre}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Teléfono"
                placeholder='Núm...'
                name='fono'
                handleValue={handleInputChange}
                value={fetchData?.fono}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Email"
                placeholder='Email...'
                name='email'
                handleValue={handleInputChange}
                value={fetchData?.email}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="Usuario ERP"
                name="usuario_erp"
                value={fetchData?.usuario_erp?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.usuarios?.map(({ usuario, nombre, codigo, id }) => ({ name: usuario ?? nombre, id: codigo ?? id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <SelectFilter
                labelText="Cod. Maxi"
                name="cod_maxi"
                value={fetchData?.cod_maxi?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.vendedores?.map(({ nombre, vendedor, codigo_vendedor, id }) => ({ name: nombre ?? vendedor, id: id ?? codigo_vendedor }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* RUT"
                placeholder='RUT...'
                name='rut'
                handleValue={handleInputChange}
                value={fetchData?.rut}
                size="col-12"
                sizeDesk="col-md-6"
              />

              {/* <FormInput
                labelText="* Clave antigua"
                placeholder='Clave antigua...'
                name='clave_antigua'
                handleValue={handleInputChange}
                value={fetchData?.clave_antigua}
                size="col-12"
                sizeDesk="col-md-6"
              /> */}

              <Switch
                name="cambiar_clave"
                value={fetchData?.cambiar_clave ? 1 : 0}
                labelText="Cambiar clave"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                isDisabled={fetchData?.cambiar_clave ? false : true}
                labelText="* Nueva clave"
                placeholder='Nueva clave...'
                name='clave'
                handleValue={handleInputChange}
                value={fetchData?.clave}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="Clave Supervisor"
                placeholder='Clave supervisor (dejar vacío para mantener anterior)'
                name='clave_supervisor'
                handleValue={handleInputChange}
                value={fetchData?.clave_supervisor}
                size="col-12"
                sizeDesk="col-md-6"
              />

            </div>

            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;
