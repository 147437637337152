import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import CustomLoader from "../../components/CustomLoader";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Services
import { responseToasterContent } from "helpers/toasterContent";
import { createProveedoresTransportes } from "services/proveedoresTransportes/create.service";
import { updateProveedoresTransportes } from "services/proveedoresTransportes/update.service";
import { getDetailProveedoresTransportes } from "services/proveedoresTransportes/getDetail.service";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/proveedores-transportes',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} proveedor de transporte`,
  title: 'Proveedores | Transportes',

  serviceCreate: createProveedoresTransportes,
  serviceUpdate: updateProveedoresTransportes,
  serviceDetail: getDetailProveedoresTransportes,

  form: {
    nombre: '',
    correo: '',
    telefono: ''
  }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()

  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    if (!id) return

    CONSTANT.serviceDetail(id)
      .then(({ data }) => {
        setForm({
          ...data,
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id])



  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    const { nombre } = form

    e.preventDefault()

    setIsConsulting(true)

    if (!nombre.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un nombre', icon: 'info' }))
    // if (!telefono.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un teléfono', icon: 'info' }))
    // if (!correo.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un correo', icon: 'info' }))

    id
      ? CONSTANT.serviceUpdate(id, form)
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          navigate(CONSTANT.redirectUrl)
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
        })

      : CONSTANT.serviceCreate(form)
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          navigate(CONSTANT.redirectUrl)
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
        })
    setIsConsulting(false)
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {true
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <FormInput
                labelText='* Nombre'
                placeholder='Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='Teléfono'
                placeholder='Teléfono...'
                name='telefono'
                value={form.telefono}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='Correo'
                placeholder='Ejemplo@dominio.com'
                name='correo'
                value={form.correo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>

          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;
