import {
   FILTER_CLEAR,
   FILTER_EMPRESAS_DETALLES_INPUT,
   RESULTADOS_EMPRESAS_DETALLES,
} from "../redux-types";

const initialState = {
   filtros: {
      fecha_desde: '',
      fecha_hasta: '',
   },
   resultados: null
}


export const empresasDetallesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_EMPRESAS_DETALLES_INPUT:
         return {
            ...state, filtros: {
               ...state.filtros,
               [action.payload.name]: action.payload.value
            }
         };

      case RESULTADOS_EMPRESAS_DETALLES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state, filtros: { ...initialState.filtros }
         };

      default:
         return state;
   }
};