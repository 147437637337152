// Redux
import { useSelector } from 'react-redux'


// Components
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { clearFilterEmpresasPagos, resultadosEmpresasPagos } from 'redux/actions/empresasPagos';
import moment from 'moment';


const CONSTANT = {
	permissionsPath: 'empresas-pagos',
	pathUrl: '/empresas-pagos',
	title: 'Pagos pendientes',
	reduxClearFilters: clearFilterEmpresasPagos,
	reduxSetResults: resultadosEmpresasPagos,
}


export const EmpresasPagosTable = () => {
	const { resultados: response } = useSelector(state => state.empresasPagos)

	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				<Table>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Empresa ID</th>
							<th>Cod. Respuesta</th>
							<th>Monto</th>
							<th>Monto Aprobado</th>
							<th>Cod. Autorización</th>
							<th>Fecha Pago</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.codigo_autorizacion}`}>
										<td className='col-1'>{data?.id}</td>
										<td>{data?.empresa_id ?? "-"}</td>
										<td>{data?.codigo_respuesta ?? "N/A"}</td>
										<td>{data?.monto ?? "-"}</td>
										<td>{data?.monto_aprobado ?? "-"}</td>
										<td>{data?.codigo_autorizacion ?? "-"}</td>
										<td>{moment(data?.fecha_pago).format('DD/MM/YYYY') ?? "-"}</td>

										<td className='col-1'>
											<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
												{/* <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}?tab=informacion`} icon="Table" /> */}

												{/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
												<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'DELETE')} />
											} */}
											</DropdownWrapper>
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}