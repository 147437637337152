
// Components
import Button from "components/Button";
import { ProveedoresTransportesFiltros } from "components/AccordionFilter/ProveedoresTransportes";
import { ProveedoresTransportesTable } from "components/Tables/ProveedoresTransportesTable";

const CONSTANT = {
	title: 'Proveedores | Transportes',
	path: 'proveedores-transportes',
	create_btn: 'Crear proveedor de transporte'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<Button text={CONSTANT.create_btn} isLink url={`/${CONSTANT.path}/create`} icon="Plus" />
			</h3>

			<ProveedoresTransportesFiltros />

			<ProveedoresTransportesTable />
		</div>
	);
}
