import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

import { EmpresasDetallesFiltros } from 'components/AccordionFilter/EmpresasDetalle/GraficosCalculos'
import Button from 'components/Button'
import Card from 'components/Card'
import ModalDescuentoSugerido from 'components/Modal/Empresas/ModalModificarDescuento'
import Table from 'components/Table'
import { PrecioFormatter } from 'helpers/precioFormatter'
import { responseStringHandler } from 'helpers/responseStringHandler'
import AccordionTable from 'layout/AccordionTable'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


const CONSTANT = {
  reduxSelector: 'empresasDetalles'
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const options = {
  scales: {
    x: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
      }
    },
    y: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
      }
    }
  }
}

const defaultDatasetData = {
  tension: 1,
  borderColor: `rgb(2, 50, 225)`,
  pointRadius: 5,
  pointBorderColor: `rgba(77, 0, 177, 0.5)`,
  pointBackgroundColor: `rgba(222, 251, 2, 0.5)`
}

const price = (v) => new PrecioFormatter(v).producto()

const GraficosCalculos = ({ data, cb }) => {
  const { resultados } = useSelector(state => state[CONSTANT.reduxSelector])
  const [modalUpdate, setModalUpdate] = useState({ display: false, data: null })
  const [chartAmount, setChartAmount] = useState(null)
  const [chartProducts, setChartProducts] = useState(null)
  const [chartOrders, setChartOrders] = useState(null)


  useEffect(() => {
    if (!resultados) return

    setChartAmount(() => ({
      labels: Object.keys(resultados?.graficos?.por_fecha).map((date) => responseStringHandler(date)),
      datasets: [{
        label: 'Monto',
        data: Object.values(resultados?.graficos?.por_fecha).map(({ monto }) => monto),
        ...defaultDatasetData,
        borderColor: `rgb(47, 225, 2)`,
      }]
    }))

    setChartProducts(() => ({
      labels: Object.keys(resultados?.graficos?.por_fecha).map((date) => responseStringHandler(date)),
      datasets: [{
        label: 'Neumático',
        data: Object.values(resultados?.graficos?.por_fecha).map(({ neumaticos }) => neumaticos),
        ...defaultDatasetData,
        borderColor: `rgb(225, 2, 2)`,
      }]
    }))

    setChartOrders(() => ({
      labels: Object.keys(resultados?.graficos?.por_fecha).map((date) => responseStringHandler(date)),
      datasets: [{
        label: 'Pedidos',
        data: Object.values(resultados?.graficos?.por_fecha).map(({ pedidos }) => pedidos),
        ...defaultDatasetData
      }]
    }))
  }, [resultados])


  const handleCloseModal = () => {
    setModalUpdate({ display: false, data: null })
  }

  const handleOpenModal = () => {
    setModalUpdate({ display: true, data })
  }


  return (
    <>
      <EmpresasDetallesFiltros />

      <ModalDescuentoSugerido isOpen={modalUpdate.display} handleClose={handleCloseModal} data={modalUpdate.data} endCb={cb} />

      <div className="row">
        {/* MARK: TABLA DE GRAFICOS */}
        <Card
          title="Gráficos"
          classes='col-md-12'
        >
          <div className='col-12 col-md-4'>
            {chartAmount
              ? <Line
                data={chartAmount}
                options={options}
              />

              : <i>Realice una búsqueda para ver el gráfico</i>
            }
          </div>

          {chartProducts &&
            <div className='col-12 col-md-4'>
              <Line
                data={chartProducts}
                options={options}
              />
            </div>
          }

          {chartOrders &&
            <div className='col-12 col-md-4'>
              <Line
                data={chartOrders}
                options={options}
              />
            </div>
          }
        </Card>
      </div>

      <div className="row">
        {/* MARK: DATOS DESCUENTOS SUGERIDOS */}
        <Card
          title="Datos de descuento sugeridos"
          classes='col-md-6'
        >
          <div className='d-flex flex-column justify-content-center gap-1 mx-auto'>
            <div className='d-flex justify-content-between gap-1'>
              <div className='d-flex flex-column justify-content-cennter gap-25 p-1'>
                <span>Neumáticos comprados: <b>{data?.descuento_sugerido?.neumaticos ?? '-'}</b></span>
                <span>Meses consecutivos: <b>{data?.descuento_sugerido?.meses ?? '-'}</b></span>
                <span>% Adicional de descuento: <b>{data?.descuento_sugerido?.porcentaje_sugerido ?? '-'}</b></span>
              </div>

              <div className='d-flex flex-column border rounded text-center p-1'>
                <b>Descuento sugerido:</b>
                <b className={`fs-3`}>{data?.datos_descuento_sugerido?.descuento_aplicable ?? '-'}</b>

                <hr className='m-25' />

                <b>Aplica para descuento sugerido:</b>
                <b className={`fs-3 ${data?.datos_descuento_sugerido?.aplica_para_sugerir ? 'text-success' : 'text-danger'}`}>{data?.datos_descuento_sugerido?.aplica_para_sugerir ? 'Sí' : 'No'}</b>
              </div>
            </div>

            <div className='d-flex gap-1 justify-content-between'>
              <Button
                text='Editar parámetros'
                className='mt-auto w-100'
                icon='Settings'
                onClick={handleOpenModal}
              />

              {/* TODO: FALTA INTEGRAR ESTE BOTON, CONSULTAR POR ENDPOINT */}
              <Button
                text='Aplicar descuento sugerido'
                className='mt-25 w-100'
                icon='Check'
                color='success'
                disabled={!data?.datos_descuento_sugerido?.aplica_para_sugerir}
              />
            </div>
          </div>
        </Card>

        {/* MARK: TABLA HISTORIAL DESCUENTOS */}
        <div className='col-md-6'>
          <AccordionTable title={`Historial descuentos (${data?.descuento_historial?.length ?? 0} resultados)`}>
            <Table pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>Usuario</th>
                  <th>Descuento aplicado</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              <tbody>
                {data?.descuento_historial?.length
                  ? data?.descuento_historial.map((data) => {
                    return (
                      <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                        <td>{data?.usuario?.nombre ?? '-'}</td>
                        <td>{data?.descuento_aplicado ?? '-'}</td>
                        <td>{moment(data?.fecha).format('DD-MM-YYYY')}</td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>
        </div>
      </div>

      <div className='row'>
        {/* MARK: DATOS PLANOS */}
        <Card
          title={'Datos generales'}
        >
          <div className='row'>
            <div className='col-12 col-md-6 d-flex flex-column'>
              <h3 className='my-0 fs-2'>Promedios</h3>

              <hr className='mt-0 mb-50' />

              <div className='d-flex flex-column fs-5'>
                <span>Monto por neumático: <b>{resultados?.datos?.prom_monto_neumatico ?? '-'}</b></span>
                <span>Monto por pedido: <b>{resultados?.datos?.prom_monto_pedido ?? '-'}</b></span>
                <span>Tiempo para iniciar pagos: <b>{resultados?.datos?.prom_inicio_pago_pedido ?? '-'}</b></span>
                <span>Tiempo para finalizar pagos: <b>{resultados?.datos?.prom_final_pago_pedido ?? '-'}</b></span>
              </div>
            </div>

            <div className='col-12 col-md-6 d-flex flex-column'>
              <h3 className='my-0 fs-2'>Totales</h3>

              <hr className='mt-0 mb-50' />

              <div className='d-flex flex-column fs-5'>
                <span># de neumáticos: <b>{resultados?.datos?.total_neumaticos ?? '-'}</b></span>
                <span># de pedidos: <b>{resultados?.datos?.total_pedidos ?? '-'}</b></span>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className='row'>
        {/* MARK: TABLA DE PEDIDOS REALIZADOS */}
        <AccordionTable title={`Pedidos realizados (${resultados?.listados?.pedidos_pagos?.length ?? 0} resultados)`}>
          <Table pb={false}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
              <tr role="row">
                <th># Pedido</th>
                <th>Monto</th>
                <th>Fecha</th>
                <th>Qty. Neumáticos</th>
                <th>Fechas pago</th>
                <th>Tiempos pago</th>
              </tr>
            </thead>
            <tbody>
              {resultados?.listados?.pedidos_pagos?.length
                ? resultados?.listados?.pedidos_pagos.map((data) => {
                  return (
                    <tr className="odd" key={`user-${data.pedido_id}-${data.fecha}`}>
                      <td className='fw-bold'>
                        <Link to={`/pedidos/detalle/${data?.pedido_id}`} rel='no_referrer' target='_blank'>{data?.pedido_id ?? '-'}</Link>
                      </td>

                      <td className='fw-bold'>{price(data?.monto) ?? '-'}</td>

                      <td className='fw-bold'>{data?.fecha}</td>

                      <td className='fw-bold'>{data?.neumaticos ?? '-'}</td>

                      <td>
                        <div className='d-flex flex-column gap-25'>
                          <span>Inicio: <b>{data?.fecha_pago_inicial ?? '-'}</b></span>
                          <span>Final: <b>{data?.fecha_pago_final ?? '-'}</b></span>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column gap-25'>
                          <span>Inicio: <b>{data?.tiempo_pago_inicial ?? '-'}</b></span>
                          <span>Final: <b>{data?.tiempo_pago_final ?? '-'}</b></span>
                        </div>
                      </td>
                    </tr>
                  )
                })
                : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
              }
            </tbody>
          </Table>
        </AccordionTable>
      </div >
    </>
  )
}

export default GraficosCalculos