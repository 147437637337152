import {
   FILTER_CLEAR,
   FILTER_EMPRESAS_DETALLES_INPUT,
   RESULTADOS_EMPRESAS_DETALLES,
} from "../redux-types";

export const empresasDetallesInputFilter = ({ name, value }) => ({ type: FILTER_EMPRESAS_DETALLES_INPUT, payload: { name, value } })

export const resultadosEmpresasDetalles = (data) => ({ type: RESULTADOS_EMPRESAS_DETALLES, payload: data })

export const clearFilterEmpresasDetalles = () => ({ type: FILTER_CLEAR });
