import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterPagosPedidos, pagosPedidoFilter, resultadosPagosPedidos } from 'redux/actions/pagosPedidos'
import { FILTER_PAGOS_PEDIDOS_INPUT, } from '../../redux/redux-types'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'
import CustomLoader from 'components/CustomLoader'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers / Services
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { responseToasterContent } from 'helpers/toasterContent'
import { getAllPagosPedidos } from 'services/pagosPedidos/getAll.service'
import { idExtractor } from 'helpers/idExtractor'
import DownloadFile from 'components/Download/DownloadFile'
import { getAll } from 'endpoints/getAll'


export const filtrosFormatter = (filters) => {
  let data = {}

  Object.entries(filters).forEach(([k, v]) => {
    if (['enviado_erp'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: idExtractor(v)
      }
    }

    if (['fecha_desde', 'fecha_hasta'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: v
      }
    }
  })

  return data
}

const CONSTANT = {
  principalService: getAllPagosPedidos,
  redirectUrl: '/pagos-pedidos',
  title: 'Búsqueda de pagos a pedidos',
  queryExportar: getAll.pagos_pedidos + '/exportar',

  reduxSelector: 'pagosPedidos',
  reduxClearFilters: clearFilterPagosPedidos,
  reduxSetResults: resultadosPagosPedidos,

  selectFilter: {
    estatusEnviado: [
      { name: 'Enviados', id: '1' },
      { name: 'No enviados', id: '0' },
      { name: 'Todos', id: '*NULL*' },
    ],
  }
}


const PagosPedidosFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const {
    enviado_erp,
    fecha_desde,
    fecha_hasta
  } = filtros
  const dispatch = useDispatch()
  const [selectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)

  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  // useEffect(() => {
  //   setIsConsulting(true)

  //   CONSTANT.principalService(undefined, showing_results)
  //     .then(res => dispatch(CONSTANT.reduxSetResults(res)))
  //     .catch(err => {
  //       console.error(err);
  //       dispatch(CONSTANT.reduxSetResults([]))
  //     })
  //     .finally(() => {
  //       setIsConsulting(false)
  //     })

  //   return () => dispatch(CONSTANT.reduxClearFilters())
  // }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return CONSTANT.principalService(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
    }
    setIsConsulting(true)

    await CONSTANT.principalService(filtrosFormatter(filtros), showing_results)
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value, name: key } = e.target

    switch (filter) {
      case FILTER_PAGOS_PEDIDOS_INPUT:
        dispatch(pagosPedidoFilter({ key, value }))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking='full' />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          <DownloadFile
            url={CONSTANT.queryExportar}
            data={{ data: filtrosFormatter(filtros) }}
            method='GET'
          />
        }
      >
        <>
          <FormInput
            type='date'
            labelText='Fecha desde'
            size='col-12'
            sizeDesk='col-md-3'
            name='fecha_desde'
            handleValue={(e) => handleAddFilter(e, FILTER_PAGOS_PEDIDOS_INPUT)}
            value={fecha_desde}
          />

          <FormInput
            type='date'
            labelText='Fecha hasta'
            size='col-12'
            sizeDesk='col-md-3'
            name='fecha_hasta'
            handleValue={(e) => handleAddFilter(e, FILTER_PAGOS_PEDIDOS_INPUT)}
            value={fecha_hasta}
          />

          <SelectFilter
            labelText='Posición'
            name='enviado_erp'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_PAGOS_PEDIDOS_INPUT)}
            optionObj={selectFilter?.estatusEnviado?.map(({ id, name }) => ({ id, name }))}
            value={enviado_erp?.split('-')?.[1] ?? 'Seleccione'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}

export default PagosPedidosFiltros