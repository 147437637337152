import axios from "axios"
import { getAll } from "../../endpoints/getAll"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"


const URL = getAll.reporte_pedidos_cotizaciones

export const getAllReportePedidosCotizaciones = async (limit, params = {}, paginationUrl = undefined) => {
  try {
    return await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(res => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}