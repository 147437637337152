// Redux
import { useDispatch, useSelector } from 'react-redux'


// Components
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Endpoints


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { getAllEmpresas } from '../../services/empresas/getAllEmpresas.service';
import { filtrosFormatter } from '../AccordionFilter/Empresas';
import { clearFilterProveedoresTransportes, resultadosProveedoresTransportes } from 'redux/actions/proveedoresTransportes';
import { getAllProveedoresTransportes } from 'services/proveedoresTransportes/getAll.service';


const CONSTANT = {
	permissionsPath: 'proveedores',
	reduxSelector: 'proveedoresTransportes',
	pathUrl: '/proveedores-transportes',
	title: 'Proveedores de Transportes',

	reduxClearFilters: clearFilterProveedoresTransportes,
	reduxSetResults: resultadosProveedoresTransportes,

	servicePrincipal: getAllProveedoresTransportes
}


// TODO: Finalizar integracion
export const ProveedoresTransportesTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		dispatch(CONSTANT.reduxSetResults(null))

		CONSTANT.servicePrincipal(showing_results, filtrosFormatter(filtros), url)
			.then(({ data }) => {
				dispatch(CONSTANT.reduxSetResults(data))
			})
			.catch(err => {
				console.error(err);
				dispatch(CONSTANT.reduxSetResults([]))
			})
	}


	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Nombre</th>
							<th>Correo</th>
							<th>Teléfono</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
										<td className='col-1'>{data?.id}</td>

										<td>{data?.nombre ?? "-"}</td>

										<td>{data?.correo ?? "-"}</td>

										<td>{data?.telefono ?? "-"}</td>

										<td className='col-1'>
											<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
												<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />

												{/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
												<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'DELETE')} />
											} */}
											</DropdownWrapper>
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={5}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}