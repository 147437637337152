import {
   FILTER_CLEAR,
   FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT,
   RESULTADOS_REPORTE_PEDIDOS_COTIZACIONES,
} from "../redux-types";

export const reportePedidosCotizacionesInputFilter = ({ key, value }) => ({ type: FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT, payload: { key, value } })

export const resultadosReportePedidosCotizaciones = (data) => ({ type: RESULTADOS_REPORTE_PEDIDOS_COTIZACIONES, payload: data })

export const clearFilterReportePedidosCotizaciones = () => ({ type: FILTER_CLEAR });
