
// Components
import { EmpresasPagosTable } from "../../components/Tables/EmpresasPagosTable";

const CONSTANT = {
	title: 'Pagos pendientes',
	path: 'empresas-pagos',
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
			</h3>

			<EmpresasPagosTable />
		</div>
	);
}
