import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster'
import { FILTER_EMPRESAS_DETALLES_INPUT } from 'redux/redux-types'
import { clearFilterEmpresasDetalles, empresasDetallesInputFilter, resultadosEmpresasDetalles } from 'redux/actions/empresasDetalles'


// Components
import FormInput from 'components/FormInput'


// Layouts
import ListadoHeader from 'layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from 'helpers/filtrosAplicadosTotal'
import { responseToasterContent } from 'helpers/toasterContent'
import { getData } from 'services/empresas/getDetail.service'


const CONSTANT = {
  redirectUrl: '/empresas',
  reduxStateSelector: 'empresasDetalles',
  title: 'Filtros',
  reduxClearFilters: clearFilterEmpresasDetalles,
  reduxSetResults: resultadosEmpresasDetalles,

  servicePrincipal: getData
}


export const EmpresasDetallesFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { fecha_desde, fecha_hasta } = filtros
  const { id } = useParams()
  const dispatch = useDispatch()

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (!fecha_desde) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha desde', icon: 'info' }))
    if (!fecha_hasta) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha hasta', icon: 'info' }))

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)

    await CONSTANT.servicePrincipal(id, filtros)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { name, value } = e.target

    switch (filter) {
      case FILTER_EMPRESAS_DETALLES_INPUT:
        dispatch(empresasDetallesInputFilter({ name, value }))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            type='date'
            labelText='Fecha desde'
            name='fecha_desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_EMPRESAS_DETALLES_INPUT)}
            value={fecha_desde}
          />

          <FormInput
            type='date'
            labelText='Fecha hasta'
            name='fecha_hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_EMPRESAS_DETALLES_INPUT)}
            value={fecha_hasta}
          />
        </>
      </ListadoHeader>
    </div>
  )
}