import Table from "components/Table"
import AccordionTable from "../../../layout/AccordionTable"

const CONSTANT = {
  title: 'Cuenta corriente'
}

export const CuentaCorrienteTable = ({ response }) => {
  return (
    <div className="row">
      <AccordionTable title={`${CONSTANT?.title} (${response?.data?.length ?? 0} resultados)`}>

        <Table pb={false}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row">
              <th>ID</th>
              <th>Nombre</th>
              <th>Categoría</th>
              <th>Ícono</th>
              <th style={{ maxWidth: '50px' }}>Creación</th>
              <th style={{ maxWidth: '50px' }}>Estatus</th>
              <th style={{ maxWidth: '50px' }}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {response?.data?.length
              ? response.data?.map((data) => {
                return (
                  <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                    <td >{data.id}</td>
                    <td>{data?.nombre ?? 'N/A'}</td>
                    <td>{data?.categoria?.nombre ?? 'N/A'}</td>
                    <td>{data.created_at.split('T')[0]}</td>

                  </tr>
                )
              })
              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >
    </div>
  )
}
