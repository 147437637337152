import {
	FILTER_CLEAR,
	FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT,
	RESULTADOS_REPORTE_PEDIDOS_COTIZACIONES,
} from "../redux-types";

const initialState = {
	filtros: {
		fecha_desde: '',
		fecha_hasta: '',
	},
	resultados: null
}


export const reportePedidosCotizacionesReducer = (state = initialState, action) => {

	switch (action.type) {
		case FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT:
			const { key, value } = action.payload

			return {
				...state, filtros: {
					...state.filtros,
					[key]: value
				}
			};

		case RESULTADOS_REPORTE_PEDIDOS_COTIZACIONES:
			return {
				...state, resultados: action.payload
			};

		case FILTER_CLEAR:
			return { ...state, filtros: { ...initialState.filtros } };

		default:
			return state;
	}
};