import Switch from "components/Switch"
import Button from "../../Button"
import FormInput from "../../FormInput"

export const ModalPlanificacion = ({ handleInputChange, form, id, handleFetch }) => {
  return (
    <>
      <p className='fw-bold fs-3'>Planificacion de nota de venta #{id}</p>
      <hr className='my-2' />

      <div className="row">
        <FormInput
          type='date'
          labelText={'Fecha'}
          handleValue={handleInputChange}
          name='planificacion%-%fecha_planificacion'
          value={form.fecha_planificacion}
          size='col-12'
          sizeDesk='col-md-4'
          classes='mx-auto'
        />

        <Switch
          name='planificacion%-%habilitar'
          type='number'
          labelText="Habilitar clave"
          size="col-12"
          sizeDesk="col-md-4 my-auto"
          handleValue={handleInputChange}
          value={form?.habilitar ? 1 : 0}
        />

        <FormInput
          isDisabled={!form?.habilitar}
          labelText={'Clave supervisor'}
          handleValue={handleInputChange}
          name='planificacion%-%clave_supervisor'
          value={form.clave_supervisor}
          size='col-12'
          sizeDesk='col-md-4'
          classes='mx-auto'
        />


      </div>

      <div>
        <Button className='mx-auto' text='Planificar' icon='Check' onClick={() => handleFetch(null, 'planificacion-create')} />
      </div>
    </>
  )
}
