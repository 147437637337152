import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster'
import { FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT } from 'redux/redux-types'
import { clearFilterReportePedidosCotizaciones, reportePedidosCotizacionesInputFilter, resultadosReportePedidosCotizaciones } from 'redux/actions/reportePedidosCotizaciones'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from 'helpers/filtrosAplicadosTotal'
import { responseToasterContent } from 'helpers/toasterContent'


// Services
import { getAllReportePedidosCotizaciones } from 'services/reportePedidosCotizaciones/getAll.service'
import DownloadFile from 'components/Download/DownloadFile'
import { getAll } from 'endpoints/getAll'
import { queryParamsFormatter } from 'helpers/queryParamsFormatter'


const CONSTANT = {
  redirectUrl: '/reporte-pedidos-cotizaciones',
  reduxStateSelector: 'reportePedidosCotizaciones',
  title: 'Búsqueda de reportes',
  reduxClearFilters: clearFilterReportePedidosCotizaciones,
  reduxSetResults: resultadosReportePedidosCotizaciones,

  principalService: getAllReportePedidosCotizaciones
}



export const ReportePedidosCotizacionesFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { fecha_desde, fecha_hasta } = filtros
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())

      return
    }

    setIsConsulting(true)

    await CONSTANT.principalService(showing_results, filtros)
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value, name: key } = e.target

    switch (filter) {
      case FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT:
        dispatch(reportePedidosCotizacionesInputFilter({ value, key }))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={user.permisos['pedidos.cotizaciones-exportar']
          ? <DownloadFile
            url={getAll.reporte_pedidos_cotizaciones + `/exportar?s=1${queryParamsFormatter(filtros)}`}
            method='GET'
          />
          : <></>
        }
      >
        <>
          <FormInput
            type={'date'}
            labelText='Fecha desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT)}
            value={fecha_desde}
            name={'fecha_desde'}
          />

          <FormInput
            type={'date'}
            labelText='Fecha hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_REPORTE_PEDIDOS_COTIZACIONES_INPUT)}
            value={fecha_hasta}
            name={'fecha_hasta'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}