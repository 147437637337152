import Accordion from 'components/Accordion'
import Button from 'components/Button';
import ModalAreaImagenes from 'components/Modal/Empresas/ModalAreaImagenes';
import ModalCrearArea from 'components/Modal/Empresas/ModalCrearArea';
import ModalCrearContacto from 'components/Modal/Empresas/ModalCrearContacto';
import Table from 'components/Table';
import React, { useState } from 'react'


// TODO: refetch de datos al actualizar o crear un registro
const Sucursales = ({ data, cb }) => {
  const [modalContacto, setModalContacto] = useState({ display: false, data: null })
  const [modalArea, setModalArea] = useState({ display: false, data: null })
  const [modalAreaImagenes, setModalAreaImagenes] = useState({ display: false, data: null })

  const handleModalContacto = (data) => {
    setModalContacto(prev => ({ data: data, display: !prev?.display }))
  }

  const handleModalArea = (data) => {
    setModalArea(prev => ({ data: data, display: !prev?.display }))
  }

  const handleModalAreaImagenes = (data) => {
    setModalAreaImagenes(prev => ({ data: data, display: !prev?.display }))
  }

  return (
    <>
      <ModalCrearContacto
        isOpen={modalContacto.display}
        data={modalContacto.data}
        handleClose={() => handleModalContacto(null)}
        endCb={cb}
      />

      <ModalCrearArea
        isOpen={modalArea.display}
        data={modalArea.data}
        handleClose={() => handleModalArea(null)}
        endCb={cb}
      />

      <ModalAreaImagenes
        isOpen={modalAreaImagenes.display}
        data={modalAreaImagenes.data}
        handleClose={() => handleModalAreaImagenes(null)}
        endCb={cb}
      />

      {data?.sucursales?.map(s => (
        <Accordion
          title={<h3 className='m-0 w-100 text-center text-white'>{s.nombre}</h3>}
          classes='col-md-12 mx-auto mb-2'
          key={`sucursal-${s.id}`}
        >
          <div className='row'>
            {/* MARK: USUARIOS */}
            <div className='col-12 col-md-6'>

              <div className='d-flex justify-content-between mx-1'>
                <h3 className='text-center fs-1 fw-bolder m-0'>Usuarios</h3>
              </div>

              <Table pb={false}>
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr role="row">
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Rol</th>
                    <th>Tlf.</th>
                  </tr>
                </thead>
                <tbody>
                  {s?.usuarios?.length
                    ? s.usuarios?.map((data) => {
                      return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                          <td>{data?.nombre ?? '-'}</td>

                          <td>{data?.email ?? '-'}</td>

                          <td>{data?.empresa_permiso?.nombre ?? '-'}</td>

                          <td>{data?.telefono ?? '-'}</td>
                        </tr>
                      )
                    })
                    : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                  }
                </tbody>
              </Table>
            </div>

            {/* MARK: CONTACTOS */}
            <div className='col-12 col-md-6'>
              <div className='d-flex justify-content-between mx-1'>
                <h3 className='text-center fs-1 fw-bolder m-0'>Contactos</h3>

                <Button
                  text='Nuevo contacto'
                  icon='Plus'
                  color='success'
                  className='p-25 my-auto'
                  onClick={() => handleModalContacto({ sucursal_id: s?.id })}
                />
              </div>

              <Table pb={false}>
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr role="row">
                    <th>Nombre</th>
                    <th>Cargo</th>
                    <th>Email</th>
                    <th>Tlf.</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {s?.contactos?.length
                    ? s.contactos?.map((data, i) => {
                      return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                          <td>{data?.nombre ?? '-'}</td>

                          <td>{data?.cargo ?? '-'}</td>

                          <td>{data?.email ?? '-'}</td>

                          <td>{data?.telefono ?? '-'}</td>

                          <td className='col-1'>
                            <Button icon={'Settings'} className={'p-25'} onClick={() => handleModalContacto({ sucursal_id: s?.id, ...data })} />
                          </td>
                        </tr>
                      )
                    })
                    : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                  }
                </tbody>
              </Table>
            </div>
          </div>

          <hr className='m-4' />

          <div className='row'>
            {/* MARK: DIRECCIONES DESPACHO */}
            <div className='col-12 col-md-6'>

              <h3 className='text-center fs-1 fw-bolder'>Direcciones de despacho</h3>

              <Table pb={false}>
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr role="row">
                    <th>Direccion entrega</th>
                    <th>Comuna</th>
                  </tr>
                </thead>
                <tbody>
                  {s?.direcciones_despacho?.length
                    ? s.direcciones_despacho?.map((data) => {
                      return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                          <td>{data?.direccion_entrega ?? '-'}</td>

                          <td>{data?.punto_entrega?.comuna?.nombre ?? '-'}</td>
                        </tr>
                      )
                    })
                    : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                  }
                </tbody>
              </Table>
            </div>

            {/* MARK: AREAS */}
            <div className='col-12 col-md-6'>
              <div className='d-flex justify-content-between mx-1'>
                <h3 className='text-center fs-1 fw-bolder m-0'>Áreas</h3>

                <Button
                  text='Nueva área'
                  icon='Plus'
                  color='success'
                  className='p-25 my-auto'
                  onClick={() => handleModalArea({ sucursal_id: s?.id })}
                />
              </div>

              <Table pb={false}>
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr role="row">
                    <th>Nombre</th>
                    <th>Dirección</th>
                    <th className='col-1'>Imágenes</th>
                    <th className='col-1'></th>
                  </tr>
                </thead>
                <tbody>
                  {s?.areas?.length
                    ? s.areas?.map(({ imagenes, descripcion, nombre, id }) => {
                      return (
                        <tr className="odd" key={`user-${id}-${nombre}`}>
                          <td>{nombre ?? '-'}</td>

                          <td>{descripcion ?? '-'}</td>

                          <td>
                            <Button
                              icon='Image'
                              className={'p-25 mx-auto'}
                              color='success'
                              onClick={() => handleModalAreaImagenes({ imagenes, id })}
                            />
                          </td>

                          <td>
                            <Button
                              icon='Settings'
                              className={'p-25'}
                              onClick={() => handleModalArea({ descripcion, nombre })}
                            />
                          </td>
                        </tr>
                      )
                    })
                    : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </Accordion>
      ))}
    </>
  )
}

export default Sucursales