import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../views/ReportePedidosCotizaciones/Listado"

export const ReportePedidosCotizacionesRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
