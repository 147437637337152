import { useEffect, useRef, useState } from "react"
import Button from "./Button"

const Modal = ({ children, display, handleValue, size, sizeDesk, modalStyle, innerModalStyle, ...props }) => {
   const modalRef = useRef(null)

   const [isDisplay, setIsDisplay] = useState(false)

   const handleEasyClose = (e) => {
      if (e.target === modalRef.current) {
         handleValue()
      }
   }

   useEffect(() => {
      setIsDisplay(display)
   }, [display])

   return (
      <div
         className={`filter-modal overflow-auto ${isDisplay ? '' : 'hidden'}`}
         style={{ overflowX: 'hidden', ...modalStyle }}
         onClick={handleEasyClose}
         ref={modalRef}
      >
         <div
            className={`${size ?? 'col'} ${sizeDesk ?? 'col-md-4'} 
            position-relative custom-scrollbar 
            rounded-3 overflow-auto mx-0 mx-md-auto my-auto`
            }
            style={{ overflowX: 'hidden', ...innerModalStyle }}
            {...props}
         >
            <Button icon='X' onClick={handleValue} />
            {children}
         </div>
      </div >
   )
}

export default Modal