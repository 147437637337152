import { Link } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterPagosPedidos, resultadosPagosPedidos } from 'redux/actions/pagosPedidos';


// Components
import Table from "../../Table";
import { filtrosFormatter } from 'components/AccordionFilter/PagosPedidos';


// Endpoints
import { getOne } from "../../../endpoints/getOne";


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


// Services 
import { getAllPagosPedidos } from 'services/pagosPedidos/getAll.service';
import { PrecioFormatter } from 'helpers/precioFormatter';
import moment from 'moment';
import { useState } from 'react';
import CustomLoader from 'components/CustomLoader';


const CONSTANT = {
	permissionsPath: 'pagos-pedido',
	pathUrl: '/pagos-pedidos',
	queryUrlGetOne: getOne.empresas,
	title: 'Pagos a pedidos',

	principalService: getAllPagosPedidos,

	reduxSelector: 'pagosPedidos',
	reduxClearFilters: clearFilterPagosPedidos,
	reduxSetResults: resultadosPagosPedidos,
}


const price = (v) => new PrecioFormatter(v).producto()

export const PagosPedidosTable = () => {
	const dispatch = useDispatch()
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const [isConsulting, setIsConsulting] = useState(false)

	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		setIsConsulting(true)

		CONSTANT.principalService(filtrosFormatter(filtros), showing_results, url)
			.then(res => {
				dispatch(CONSTANT.reduxSetResults(res))
			})
			.catch(err => {
				console.error(err);
				dispatch(CONSTANT.reduxSetResults([]))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}


	return (
		<div className='col'>
			{isConsulting && <CustomLoader blocking='full' />}

			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				<Table response={response?.data} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>ID Pedido</th>
							<th>Nota de venta</th>
							<th>Método pago</th>
							<th>Monto</th>
							<th>Ref. ERP</th>
							<th>Fecha Pago</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.data?.length
							? response.data.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.pedido_id}`}>
										<td className='col-1'>{data?.id}</td>

										<td className='col-1'>{data?.pedido_id
											? <Link to={`/pedidos/detalle/${data?.pedido_id}`}>{data?.pedido_id}</Link>
											: '-'
										}
										</td>

										<td className='col-1'>{data?.nota_venta
											? <Link to={`/erp/notas-ventas/detalle/${data?.nota_venta}`}>{data?.nota_venta}</Link>
											: '-'
										}
										</td>

										<td className='col-1'>{data?.metodo_pago?.nombre ?? '-'}</td>

										<td className='col-1 fw-bold'>{price(data?.monto) ?? '-'}</td>

										<td className='col-1'>{data?.ref_pago_erp ?? '-'}</td>

										<td className='col-1'>{moment(data?.fecha_pago).format('DD/MM/YYYY') ?? '-'}</td>
									</tr>
								)
							})

							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</div>
	)
}