import axios from "axios"
import { getOne } from "../../endpoints/getOne"
import { getAll } from "../../endpoints/getAll"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"

export const getDetail = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" empresa`)

    return await axios(getOne.empresas(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}

export const getData = async (id, params = {}) => {
  try {
    return await axios(`${getAll.empresas_data}/${id}?s=1${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}


// TODO: Error 500 en el endpoint 
export const getCuentaCorriente = async (empresa_id, limit = 20) => {
  try {
    if (!empresa_id) throw Error(`Missing "id" empresa`)

    return await axios(`${getAll.empresas}/cuenta-corriente?s=1${queryParamsFormatter({ empresa_id, limit })}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}