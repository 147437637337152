import {
   FILTER_CLEAR,
   RESULTADOS_EMPRESAS_CLASIFICACIONES,
} from "../redux-types";

// export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosEmpresasClasificaciones = (data) => ({ type: RESULTADOS_EMPRESAS_CLASIFICACIONES, payload: data })

export const clearFilterEmpresasClasificaciones = () => ({ type: FILTER_CLEAR });
