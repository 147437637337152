import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import CustomLoader from "../../components/CustomLoader";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Services
import { responseToasterContent } from "helpers/toasterContent";
import { createProveedoresTransportes } from "services/proveedoresTransportes/create.service";
import { updateEmpresas } from "services/empresas/update.service";
import { getDetail } from "services/empresas/getDetail.service";
import { getAllEmpresasClasificaciones } from "services/empresasClasificaciones/getAll.service";
import SelectFilter from "components/SelectFilter";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/empresas',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} empresa`,
  title: 'Empresas',

  serviceCreate: createProveedoresTransportes,
  serviceUpdate: updateEmpresas,
  serviceDetail: getDetail,

  form: {
    clasificacion_id: '',
    direccion_fiscal: '',
    nombre_local: '',
    venta_estimada_mensual: '',
    porcentaje_descuento: '',
  },

  selectFilter: {
    clasificaciones: [],
  }
}


const fetchSelectFilter = async () => {
  const clasificaciones = await getAllEmpresasClasificaciones(99999)
    .then(({ data }) => data.data)
    .catch(err => { console.error(err) })

  return { clasificaciones }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)

  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    fetchSelectFilter().then(x => setSelectFilter(x))
  }, [])


  useEffect(() => {
    if (!id || selectFilter?.clasificaciones?.length) return

    CONSTANT.serviceDetail(id)
      .then(({ data }) => {
        const selectedClasificacion = selectFilter.clasificaciones.find(({ id }) => Number(id) === Number(data?.clasificacion_id))

        setForm({
          ...data,
          clasificacion_id: `${selectedClasificacion?.id ?? '*NULL*'}-${selectedClasificacion?.nombre ?? 'Sin selección'}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id, selectFilter?.clasificaciones])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    const { nombre } = form

    e.preventDefault()

    setIsConsulting(true)

    if (!nombre.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un nombre', icon: 'info' }))
    // if (!telefono.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un teléfono', icon: 'info' }))
    // if (!correo.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un correo', icon: 'info' }))

    id
      ? CONSTANT.serviceUpdate(id, form)
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          navigate(CONSTANT.redirectUrl)
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
        })

      : CONSTANT.serviceCreate(form)
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          navigate(CONSTANT.redirectUrl)
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
        })
    setIsConsulting(false)
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto">
        <div className='row'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} className='col-6' />

          {form?.id &&
            <Button text={`Volver al detalle #${form?.id}`} icon='File' style='flat' isLink url={`/empresas/detalle/${form?.id}`} className='col-6' />
          }
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>

          <div className="row">
            <SelectFilter
              name="clasificacion_id"
              value={form.clasificacion_id?.split('-')?.[1] ?? 'Seleccione'}
              labelText="* Clasificacion"
              placeholder="Seleccione"
              handleValue={handleInputChange}
              optionObj={selectFilter?.clasificaciones?.map(({ nombre, id }) => ({ id, name: nombre }))}
              size="col-12"
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='Nombre local'
              placeholder='Ingrese un nombre'
              name='nombre_local'
              value={form.nombre_local}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='Dirección fiscal'
              placeholder='Ingrese una dirección fiscal'
              name='direccion_fiscal'
              value={form.direccion_fiscal}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <FormInput
              type='number'
              labelText='Venta estimada mensual' ra
              placeholder='Ingrese una estimación'
              name='venta_estimada_mensual'
              value={form.venta_estimada_mensual}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              type='number'
              labelText='% Descuento'
              placeholder='Ingrese un porcentaje'
              name='porcentaje_descuento'
              value={form.porcentaje_descuento}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div >
    </div >
  );
};

export default Form;
