import { Route, Routes } from "react-router-dom"
import Form from "../../views/ProveedoresTransportes/Form"
import { Listado } from "../../views/ProveedoresTransportes/Listado"

const ProveedoresTrasporteRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />
      </Routes>
   )
}

export default ProveedoresTrasporteRouter