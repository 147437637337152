import { CuentaCorrienteTable } from 'components/Tables/Empresas/CuentaCorrienteTable'
import React, { useEffect, useState } from 'react'
import { getCuentaCorriente } from 'services/empresas/getDetail.service'

const CuentaCorriente = ({ data }) => {
  const [response, setResponse] = useState(null)
  
  useEffect(() => {
    if (!data?.id) return

    getCuentaCorriente(data?.id)
      .then(({ data }) => {
        setResponse(data)
      })
      .catch(err => {
        console.error(err);
      })

    return () => {
      setResponse(null)
    }
  }, [data?.id])
  console.log(response);
  

  return (
    <>
      <CuentaCorrienteTable response={response} />
    </>
  )
}

export default CuentaCorriente