import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"

// Redux
import { fireToaster } from "../../../redux/actions/toaster"


// Components
import Modal from "../../Modal"
import FormInput from "../../FormInput"
import Button from "../../Button"


// Services
import { responseToasterContent } from "helpers/toasterContent"
import { updateEmpresasDescuentoSugerido } from "services/empresas/updateEmpresasDescuentoSugerido.service"


const CONSTANT = {
  form: {
    neumaticos: '',
    meses: '',
    porcentaje_sugerido: '',
  },
}

const ModalDescuentoSugerido = ({ data, isOpen, handleClose, endCb }) => {
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()


  useEffect(() => {
    setForm(prev => ({
      ...prev,
      neumaticos: data?.descuento_sugerido?.neumaticos,
      meses: data?.descuento_sugerido?.meses,
      porcentaje_sugerido: data?.descuento_sugerido?.porcentaje_sugerido,
    }))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [isOpen, data])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()

    // if (!form.neumaticos) return dispatch(fireToaster({ title: 'La cantidad de neumaticos es requerida', icon: 'info' }))
    // if (!form.meses) return dispatch(fireToaster({ title: 'La cantidad de meses es requerida', icon: 'info' }))
    // if (!form.porcentaje_sugerido) return dispatch(fireToaster({ title: 'El porcentaje sugerido es requerido', icon: 'info' }))

    await updateEmpresasDescuentoSugerido(data?.id, { ...form })
      .then(res => {
        handleClose()
        dispatch(fireToaster(responseToasterContent(res)))
        endCb()
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
        console.error(err);
      })

  }


  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3>Datos de descuento sugerido | Empresa #{data?.id}</h3>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormInput
            type='number'
            labelText='* Cantidad de neumáticos comprados'
            placeholder='Ingrese un cantidad'
            size='col-12'
            name={`neumaticos`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.neumaticos ?? ''}
          />

          <FormInput
            type='number'
            labelText='* Cantidad de meses consecutivos'
            placeholder='Ingrese una cantidad'
            size='col-12'
            name={`meses`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.meses ?? ''}
          />

          <FormInput
            type='number'
            labelText='* Porcentaje adicional de descuento'
            placeholder='Ingrese un porcentaje'
            size='col-12'
            name={`porcentaje_sugerido`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.porcentaje_sugerido ?? ''}
          />
          <div className="col-12 d-flex justify-content-around mt-4">
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} icon={'X'} />
            <Button text={`Guardar`} onClick={handleSubmit} color={'success'} icon={'Check'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalDescuentoSugerido