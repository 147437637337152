import axios from "axios"
import { getAll } from "../../endpoints/getAll"

const URL = getAll.pedidos

export const updatePedido = async (id, data) => {
  try {
    return await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data: { ...data, pedido_id: id }
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}