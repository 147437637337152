import axios from "axios"
import { getAll } from "../../endpoints/getAll"


const URL = getAll.empresas_areas_imagenes


export const deleteEmpresaAreasImagenes = async (id) => {
  try {
    return await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'DELETE',
    })
      .then((res) => res)
      .catch(err => { throw err })

  } catch (err) {
    return Promise.reject(err)
  }
}