import {
   FILTER_CLEAR,
   RESULTADOS_PROVEEDORES_TRANSPORTES,
} from "../redux-types";

// export const busquedaFilter = (data) => ({ type: FILTSR_BUSQUEDA, payload: data })

export const resultadosProveedoresTransportes = (data) => ({ type: RESULTADOS_PROVEEDORES_TRANSPORTES, payload: data })

export const clearFilterProveedoresTransportes = () => ({ type: FILTER_CLEAR });
